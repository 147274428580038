import { createRouter, createWebHistory } from 'vue-router'

import { getCurrentFirebaseUser } from '@/utils/firebase'

import Dashboard from '@/pages/DashboardPage.vue'
// import { store } from '@/store'
// import { UserRole } from '@/api/user/models/UserRole'
import AppRoutes from './routes'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: '/dashboard'
		},
		{
			name: AppRoutes.dashboard,
			path: '/dashboard',
			component: Dashboard,
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.downloadStatisticsReport,
			// e.g. https://campaigns-dev.contactpoint.app/api/candidateaccounts/44938500-4b15-4a23-accc-741f9aac1273/campaigns/statistics/638487741778690405-surveys
			path: '/api/candidateaccounts/:accountId/campaigns/statistics/:reportFileName',
			meta: {
				requiresAuth: true
			},
			component: Dashboard,
			props: (route) => ({
				candidateAccountId: route.params.accountId as string,
				reportFileName: route.params.reportFileName as string
			})
		},
		{
			name: AppRoutes.manageUsers,
			path: '/manage-users',
			component: () => import('@/pages/ManageUsersPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.addStaff,
			path: '/add-staff',
			component: () => import('@/pages/AddStaffPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.voterContactLists,
			path: '/voter-contact-lists',
			component: () => import('@/pages/VoterContactListsPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.eventContacts,
			path: '/event-contacts',
			component: () => import('@/pages/EventContactsPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.activismTiles,
			path: '/activisms-tiles',
			component: () => import('@/pages/ActivismTilesPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.platformApps,
			path: '/platform-apps',
			component: () => import('@/pages/PlatformAppsPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.createPlatformApp,
			path: '/platform-apps/create',
			component: () => import('@/pages/CreatePlatformAppPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.earlyBallots,
			path: '/early-ballots',
			component: () => import('@/pages/EarlyBallotsPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.events,
			path: '/events',
			component: () => import('@/pages/EventsPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.campaignsService,
			path: '/campaigns/:type',
			component: () => import('@/pages/CampaignServicePage.vue'),
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				type: route.params.type as string
			})
		},
		{
			name: AppRoutes.customCampaign,
			path: '/custom-campaign/create',
			component: () => import('@/pages/CustomCampaignPage.vue'),
			meta: {
				requiresAuth: true
			}
		},
		{
			name: AppRoutes.statistics,
			path: '/statistics',
			meta: {
				requiresAuth: true
			},
			component: () => import('@/pages/StatisticsAndMetrics.vue')
		},
		{
			name: AppRoutes.signUp,
			path: '/auth/action',
			component: () => import('@/pages/SignUpPage.vue')
		},
		{
			name: AppRoutes.login,
			path: '/login',
			component: () => import('@/pages/LoginPage.vue'),
			props: (route) => ({
				redirectUrl: route.query.redirect_url,
				candidateAccountId: route.query.candidate_account
			})
		},
		{
			name: AppRoutes.resetPassword,
			path: '/reset-password',
			component: () => import('@/pages/ResetPasswordPage.vue')
		},
		{
			name: AppRoutes.notFound,
			path: '/404',
			component: () => import('@/pages/NotFoundPage.vue')
		},
		{
			path: '/:catchAll(.*)',
			redirect: '/404'
		}
	]
})

router.beforeEach(async (to, _, next) => {
	const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)

	if (!requiresAuth) {
		next()
	} else {
		try {
			const firebaseUser = await getCurrentFirebaseUser()

			const isAuthorized = !!firebaseUser && !firebaseUser.isAnonymous

			if (requiresAuth && !isAuthorized) {
				next({ path: '/login', query: { redirect_url: window.location.pathname } })
			} else {
				next()
			}
		} catch (e) {
			next('/login')
		}
	}
})

export default router
