import EventContact from '@/api/contact-lists/models/EventContact'
import ItemsResult from '@/api/models/ItemsResult'
import ApiService from '@/services/api-service'

class EventContactsApi extends ApiService {
	async getAllContacts(candidateAccountId: string) {
		return (
			await this.apiGet<ItemsResult<EventContact>>(`/candidateaccounts/${candidateAccountId}/eventcontacts/all`, {
				params: {
					Top: 1000
				}
			})
		).Result
	}
}

export default new EventContactsApi()
