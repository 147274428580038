import { AxiosRequestConfig } from 'axios'
import Qs from 'qs'

import ApiService from '@/services/api-service'

import { MetricItem } from './models/Metric'

class StatisticsApi extends ApiService {
	async generateStatisticsLink(candidateAccountId: string, from: string, to?: string) {
		return (
			await this.apiPost<string[]>(
				`/candidateaccounts/${candidateAccountId}/campaigns/statistics?from=${from}&to=${to}`
			)
		).Result
	}

	async getStatisticsLink(candidateAccountId: string, fileName: string) {
		return this.httpInstance.get<string>(`/candidateaccounts/${candidateAccountId}/campaigns/statistics/${fileName}`)
	}

	async getMetrics(candidateAccountId: string, type: string, from: string, campaignId: string, to: string) {
		const axiosConfig: AxiosRequestConfig = {
			params: {
				type,
				from,
				to,
				campaignId
			},
			paramsSerializer(params) {
				return Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true, encode: true })
			}
		}

		return (await this.apiGet<MetricItem[]>(`/candidateaccounts/${candidateAccountId}/metrics`, axiosConfig)).Result
	}
}

export default new StatisticsApi()
