const eventListenerMap = new Map()

const validateHandler = (handler: (event: PointerEvent, element: HTMLElement) => void) => {
	const isFunction = typeof handler === 'function'

	if (!isFunction) {
		throw new Error('v-click-outside: Binding value should be a function or an object')
	}

	return handler
}

const clickOutside = {
	beforeMount(el: HTMLElement, { value }: { value: () => void }) {
		const validHandler = validateHandler(value)

		const clickOutsideEvent = (event: any) => {
			const isClickOutside = event.target !== el && !el.contains(event.target as Node)

			if (!isClickOutside) return null

			return validHandler(event, el)
		}

		eventListenerMap.set(el, clickOutsideEvent)

		document.body.addEventListener('click', clickOutsideEvent, false)
	},
	unmounted(el: HTMLElement) {
		const eventListener = eventListenerMap.get(el)

		document.body.removeEventListener('click', eventListener, false)

		eventListenerMap.delete(el)
	}
}

export default clickOutside
