import ApiService from '@/services/api-service'
import User from '@/api/user/models/User'
import { UserRolesResponse } from '@/api/user/models/UserRole'

class UsersApi extends ApiService {
	async getMe() {
		return (await this.apiGet<User>('/users/me')).Result
	}

	async getRole(userId: string) {
		return (await this.apiGet<UserRolesResponse>(`/users/${userId}/roles`)).Result[0]
	}
}

export default new UsersApi()
