<script lang="ts" setup>
import { onMounted } from 'vue'

import { useStore } from '@/store'
import { AuthActionTypes } from '@/store/modules/auth/types'

import AppInfoMessage from '@/components/AppInfoMessage.vue'

const store = useStore()

onMounted(() => {
	store.dispatch(AuthActionTypes.CHECK_AUTHENTICATION)
})
</script>

<template>
	<router-view v-slot="{ Component }">
		<notifications group="errors" position="bottom right">
			<template #body="{ item, close }">
				<AppInfoMessage
					v-if="item"
					:message="
						((item?.data?.error?.data?.Errors
							? item?.data?.error?.data?.Errors[0]?.Message
							: item?.data?.error?.statusText) ??
							item.data.error ??
							'<br /><br />Sorry, something went wrong.') + '<br /><br />Please contact our support team'
					"
					@on-close-tap="close"
					:color="'#bccee5'"
				/>
			</template>
		</notifications>

		<notifications group="success" position="bottom right">
			<template #body="{ item, close }">
				<AppInfoMessage v-if="item" :message="item?.data?.message" @on-close-tap="close" :color="'#75df57'" />
			</template>
		</notifications>

		<notifications group="warning" position="bottom right">
			<template #body="{ item, close }">
				<AppInfoMessage v-if="item" :message="item?.data?.message" @on-close-tap="close" :color="'#d7bb24'" />
			</template>
		</notifications>

		<component :is="Component" />
	</router-view>
</template>

<style lang="scss">
@import '@/scss/variables.scss';
@import 'scss/mixins/fonts';

@include font('Montserrat-Light', normal, 300, '/fonts/Montserrat-Light.ttf');
@include font('Montserrat-Regular', normal, 400, '/fonts/Montserrat-Regular.ttf');
@include font('Montserrat-Medium', normal, 500, '/fonts/Montserrat-Bold.ttf');
@include font('Montserrat-SemiBold', normal, 600, '/fonts/Montserrat-SemiBold.ttf');
@include font('Montserrat-Bold', normal, 700, '/fonts/Montserrat-Bold.ttf');
@include font('Proxima-Light', normal, 300, '/fonts/Proxima-Nova-Light.otf');
@include font('Proxima-Regular', normal, 400, '/fonts/Proxima-Nova-Regular.otf');
@include font('Proxima-Medium', normal, 500, '/fonts/Proxima-Nova-Medium.otf');
@include font('Proxima-Bold', normal, 700, '/fonts/Proxima-Nova-Bold.otf');
@include font('RussoOne', normal, 500, '/fonts/Russo_One.ttf');
// @include font('SFProL', normal, 100, '/fonts/SFProDisplay-Ultralight.woff2');
// @include font('SFProR', normal, 400, '/fonts/SFProDisplay-Regular.woff2');
// @include font('SFProM', normal, 500, '/fonts/SFProDisplay-Medium.woff2');

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

#app {
	font-family: Montserrat-Regular, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	height: 100%;
}

#menus {
	font-family: Montserrat-Regular, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.vue-notification-group {
	width: 350px !important;
}

.text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
}

.btn {
	width: 100%;
	height: 50px;
	background: $dark-blue;
	border-radius: 8px;
	border: none;
	outline: none;
	font-size: 16px;
	color: $white;
	text-transform: uppercase;
	cursor: pointer;

	&:disabled {
		background: $disabled;
		cursor: default;
	}
}

h1,
h2 {
	color: $sf-blue;
}

a.link {
	font-size: 17px;
	color: $sf-blue;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.app-toggle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;

	.label {
		width: calc(100% - 80px);
		cursor: pointer;

		h3 {
			font-size: 19px;
			margin-bottom: 8px;
			color: $sf-blue;
			text-transform: uppercase;
		}

		p {
			font-size: 17px;
			color: #979797;
		}
	}

	.check {
		display: inline-block;
		height: 30px;
		width: 60px;
		position: relative;
		overflow: hidden;
		border-radius: 20px;
		overflow: hidden;
		cursor: pointer;

		&__check {
			z-index: 2;
			opacity: 0;
			cursor: pointer;

			&:checked ~ .check__indicator {
				background: #7befb2;
			}

			&:checked ~ .check__indicator:after {
				transform: translate(30px, 0);
			}
		}

		&__check,
		&__indicator {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: 0;
		}

		&__indicator {
			background: #ececec;
			transition: background 0.25s ease;

			&:after {
				height: 24px;
				width: 24px;
				background: #fff;
				content: '';
				position: absolute;
				border-radius: 100%;
				top: 3px;
				left: 3px;
				transition: transform 0.25s ease;
			}
		}
	}
}
</style>
