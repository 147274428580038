import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import CampaignsApi from '@/api/campaign'
import logger from '@/services/log-service'
import { showErrorMessage, showSuccessMessage } from '@/utils/toast-message'

import type { RootState } from '@/store/index'
import { CampaignsState, Actions, Mutations, Getters, CampaignsActionTypes, CampaignsMutationTypes } from './types'

export const candidateAccountState: CampaignsState = {
	isLoading: false,
	campaigns: [],
	campaignsPagination: {
		After: null,
		IsEnd: false
	},
	activistsCursor: ''
}

const actions: ActionTree<CampaignsState, RootState> & Actions = {
	async [CampaignsActionTypes.FETCH_ALL_DATA]({ commit, dispatch }, { accountId, campaignType }) {
		try {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, true)

			await dispatch(CampaignsActionTypes.GET_CAMPAIGNS, { accountId, initial: true, campaignType })

			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)
		} catch (error: any) {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, true)

			logger.error('Something went wrong while fetching all data for campaigns', error)

			showErrorMessage(error)
		}
	},

	async [CampaignsActionTypes.GET_CAMPAIGNS]({ commit, state }, { accountId, initial, campaignType }) {
		try {
			const campaignsPerPage = 50

			const campaignsResponse = await CampaignsApi.getCampaigns(accountId, {
				Top: campaignsPerPage,
				After: initial ? undefined : state.campaignsPagination.After ?? undefined,
				Types: [campaignType]
			})

			const campaigns = campaignsResponse.Items

			commit(CampaignsMutationTypes.SET_CAMPAIGNS, {
				campaigns,
				initial
			})

			if (campaigns.length < campaignsPerPage) {
				commit(CampaignsMutationTypes.SET_CAMPAIGNS_PAGINATION, {
					After: null,
					IsEnd: true
				})
			} else {
				commit(CampaignsMutationTypes.SET_CAMPAIGNS_PAGINATION, {
					After: campaignsResponse.Cursors.After,
					IsEnd: false
				})
			}
		} catch (error) {
			logger.error('Something went wrong while fetching candidate accounts campaigns', error)

			showErrorMessage(error)
		}
	},

	async [CampaignsActionTypes.SEARCH_CAMPAIGN]({ commit }, { accountId, campaignType, query }) {
		try {
			return await CampaignsApi.searchCampaign(accountId, campaignType, query)
		} catch (error) {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			logger.error('Something went wrong while updating the campaign', error)

			showErrorMessage(error)

			return { Items: [], Cursors: { After: '', Before: '' }, TotalCount: 0 }
		}
	},

	async [CampaignsActionTypes.UPDATE_CAMPAIGN](
		{ commit, state },
		{ accountId, campaignId, surveyId, surveyQuestions, operations }
	) {
		try {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, true)

			const updatedCampaign = await CampaignsApi.updateCampaign(accountId, campaignId, operations)

			if (surveyId && surveyQuestions && surveyQuestions.length > 0) {
				await CampaignsApi.updateSurvey(surveyId, [
					{
						path: '/Questions',
						op: 'replace',
						value: surveyQuestions.map((question) => {
							return {
								Type: 'SingleChoice', // todo: add support for MultipleChoice and OpenEnded
								Text: question.Text,
								Answers: question.Answers.map((answer) => answer.Text)
							}
						})
					}
				])
			}

			const updatedCampaignsList = state.campaigns.map((campaign) =>
				campaign.Id === updatedCampaign.Id ? { ...campaign, ...updatedCampaign } : campaign
			)

			commit(CampaignsMutationTypes.SET_CAMPAIGNS, { campaigns: updatedCampaignsList, initial: true })

			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			showSuccessMessage('Campaign successfully updated!')

			return true
		} catch (error: any) {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			logger.error('Something went wrong while updating the campaign', error)

			showErrorMessage(error)

			return false
		}
	},

	async [CampaignsActionTypes.REMOVE_CAMPAIGN]({ commit }, { accountId, campaignId }) {
		try {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, true)

			await CampaignsApi.removeCampaign(accountId, campaignId)

			showSuccessMessage('Campaign has been removed')

			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			return true
		} catch (error: any) {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			logger.error('Something went wrong while removing the campaign', error)

			showErrorMessage(error)

			return false
		}
	},

	async [CampaignsActionTypes.CREATE_CAMPAIGN](
		{ commit, state },
		{ accountId, campaignType, campaignPayload, activists }
	) {
		try {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, true)

			const newCampaign = await CampaignsApi.createCampaign(accountId, campaignType, campaignPayload, activists ?? [])

			const newCampaignsList = [...state.campaigns, newCampaign]

			commit(CampaignsMutationTypes.SET_CAMPAIGNS, { campaigns: newCampaignsList, initial: true })

			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			showSuccessMessage('Campaign successfully created!')

			return true
		} catch (error: any) {
			commit(CampaignsMutationTypes.SET_LOADING_STATUS, false)

			logger.error('Something went wrong while creating a new campaign', error?.data?.Message)

			showErrorMessage(error)

			return false
		}
	}
}

const mutations: MutationTree<CampaignsState> & Mutations = {
	[CampaignsMutationTypes.SET_CAMPAIGNS](moduleState, payload) {
		if (payload.initial) {
			moduleState.campaigns = payload.campaigns
		} else {
			moduleState.campaigns.push(...payload.campaigns)
		}
	},

	[CampaignsMutationTypes.SET_CAMPAIGNS_PAGINATION](moduleState, pagination) {
		moduleState.campaignsPagination = pagination
	},

	[CampaignsMutationTypes.SET_ACTIVISTS_CURSOR](moduleState, cursor) {
		moduleState.activistsCursor = cursor
	},

	[CampaignsMutationTypes.SET_LOADING_STATUS](moduleState, status) {
		moduleState.isLoading = status
	}
}

export const getters: GetterTree<CampaignsState, RootState> & Getters = {}

export const store: Module<CampaignsState, RootState> = {
	state: candidateAccountState,
	getters,
	mutations,
	actions
}
