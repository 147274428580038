<template>
	<div class="info-message" @click="emit('on-close-tap')" :style="{ backgroundColor: props.color }">
		<div class="info-message__content">
			<p class="title" v-html="props.message"></p>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	message: String,
	color: String
})

const emit = defineEmits<{
	(e: 'on-close-tap'): void
}>()
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.info-message {
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 10px;
	margin-right: 10px;
	position: relative;
	box-sizing: border-box;
	font-size: 16px;
	color: $white;

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		word-wrap: break-word;
		.title {
			font-size: 18px;
		}
	}

	&__close {
		padding: 0;
		background: none;
		outline: none;
		box-shadow: none;
		border: none;
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;

		svg {
			width: 16px;
			height: 16px;
			color: $white;
		}
	}
}
</style>
