<script lang="ts" setup>
const props = withDefaults(defineProps<{ size?: string | number; fullScreen?: boolean }>(), {
	size: 80,
	fullScreen: true
})
</script>

<template>
	<div
		class="loader"
		automation-tag="app-loader"
		:style="{
			left: props.fullScreen ? '270px' : 0
		}"
	>
		<div
			class="loader__inner"
			:style="{
				width: `${props.size}px`,
				height: `${props.size}px`
			}"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				width="200px"
				height="200px"
				viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid"
			>
				<g transform="translate(20 20)">
					<rect x="-15" y="-15" width="30" height="30" fill="#B6D8F6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.4s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(50 20)">
					<rect x="-15" y="-15" width="30" height="30" fill="#4F7CA6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.3s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(80 20)">
					<rect x="-15" y="-15" width="30" height="30" fill="#364771">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.2s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(20 50)">
					<rect x="-15" y="-15" width="30" height="30" fill="#4F7CA6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.3s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(50 50)">
					<rect x="-15" y="-15" width="30" height="30" fill="#364771">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.2s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(80 50)">
					<rect x="-15" y="-15" width="30" height="30" fill="#4F7CA6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.1s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(20 80)">
					<rect x="-15" y="-15" width="30" height="30" fill="#364771">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.2s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(50 80)">
					<rect x="-15" y="-15" width="30" height="30" fill="#4F7CA6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="-0.1s"
						></animateTransform>
					</rect>
				</g>
				<g transform="translate(80 80)">
					<rect x="-15" y="-15" width="30" height="30" fill="#B6D8F6">
						<animateTransform
							attributeName="transform"
							type="scale"
							repeatCount="indefinite"
							calcMode="spline"
							dur="1s"
							values="1;1;0.2;1;1"
							keyTimes="0;0.2;0.5;0.8;1"
							keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
							begin="0s"
						></animateTransform>
					</rect>
				</g>
			</svg>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.loader {
	// width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
	background-color: rgb(255, 255, 255);
	z-index: 50;

	&__inner {
		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
