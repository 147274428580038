import { getApp, initializeApp } from 'firebase/app'
import { Auth, User, getAuth, onAuthStateChanged } from 'firebase/auth'
import {
	ensureInitialized,
	fetchAndActivate,
	getRemoteConfig,
	getValue,
	RemoteConfig,
	Value
} from 'firebase/remote-config'

import { store } from '@/store'

const remoteConfigs: { [key: string]: RemoteConfig } = {}

export const initFirebase = () => {
	Object.keys(import.meta.env).forEach((envKey) => {
		if (envKey.startsWith('VITE_FIREBASE_CONFIG')) {
			const firebaseConfig = JSON.parse(import.meta.env[envKey])

			const appName = firebaseConfig.projectId

			initializeApp(firebaseConfig, appName)

			remoteConfigs[appName] = getRemoteConfig(getApp(appName))
		}
	})
}

export const fetchAndActivateRemoteConfig = async (): Promise<boolean> => {
	const firebaseAppName = store.state.candidateAccount.selectedCandidateAccount?.FirebaseAccount

	if (!firebaseAppName) {
		return Promise.resolve(false)
	}

	return fetchAndActivate(remoteConfigs[firebaseAppName])
}

export const getRemoteConfigValue = (key: string): Promise<Value | null> => {
	const firebaseAppName = store.state.candidateAccount.selectedCandidateAccount?.FirebaseAccount

	if (!firebaseAppName) {
		return Promise.resolve(null)
	}

	const remoteConfig = remoteConfigs[firebaseAppName]

	if (!remoteConfig) {
		return Promise.resolve(null)
	}

	return ensureInitialized(remoteConfig).then(() => {
		return getValue(remoteConfig, key)
	})
}

export const getFirebaseAuth = (firebaseAppName: string): Auth => getAuth(getApp(firebaseAppName))

export const getCurrentFirebaseUser = async (): Promise<User | null> => {
	const firebaseAppName = store.state.candidateAccount.selectedCandidateAccount?.FirebaseAccount

	if (!firebaseAppName) {
		return null
	}

	const auth = getFirebaseAuth(firebaseAppName)

	if (!auth) {
		return null
	}

	return new Promise<User | null>((resolve, reject) => {
		const unsubscribe = onAuthStateChanged(
			auth,
			(user) => {
				unsubscribe()
				resolve(user)
			},
			reject
		)
	})
}

export const getFirebaseIdToken = async (): Promise<string | null | undefined> => {
	const firebaseAppName = store.state.candidateAccount.selectedCandidateAccount?.FirebaseAccount

	if (!firebaseAppName) {
		return null
	}

	try {
		return getFirebaseAuth(firebaseAppName).currentUser?.getIdToken()
	} catch (e) {
		return null
	}
}
