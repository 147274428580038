import { Gender } from './ContactsQuery'

export enum ContactListType {
	PreDefined = 'PreDefined',
	LocalDefined = 'LocalDefined'
}

export interface LocalStoredContactsMatchedData {
	name: string
	type: string
	size: number
	isUploaded: boolean
	isMatched: boolean
	totalContactsMatched: number
	uploadedUrl: string
	operationId: string
}

export interface ContactListSearchFilter {
	AgeFrom?: number
	AgeTo?: number
	Gender?: Gender
	City?: string
	CongressionalDistricts?: number[]
	LegislativeDistricts?: number[]
	PoliticalParties?: string[]
	Precincts?: string[]
	Counties?: string[]
	Tags?: string[]
	States?: string[]
	EarlyBallots?: string[]
	VoterEfficacy?: number | null
	ZipCode?: string // notice: not present in UI
	// PhonesFilter?: string // notice: not supported by the Platform yet
}

export interface ContactList {
	Id: string
	CandidateAccountId: string
	Name: string
	TotalRecords: string
	CreatedAt: string
	UpdatedAt: string
	UpdatedBy: string
	Type: ContactListType
	SearchFilter: ContactListSearchFilter | null
}

export enum ContactMatchingEventsTypes {
	VoterMatchProgressEvent = 'VoterMatchProgressEvent',
	VoterMatchCompletedEvent = 'VoterMatchCompletedEvent'
}

export interface ContactMatchingData {
	TotalContacts: number
	ProcessedContacts: number
	Percentage: number
}

export interface ContactMatchingSignalREvent {
	Type: ContactMatchingEventsTypes
	Data: ContactMatchingData
}
