<script lang="ts" setup>
// import AppHeader from '@/components/AppHeader.vue'
import AppNav from '@/components/AppNav.vue'
import Loader from '@/components/AppLoader.vue'

interface Props {
	hasNav?: boolean
	isSolidBackground?: boolean
	hasNoBackground?: boolean
	isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	hasNav: true,
	isSolidBackground: false,
	hasNoBackground: false,
	isLoading: false
})
</script>

<template>
	<div
		class="layout"
		:class="{
			'layout--has-nav': props.hasNav,
			'layout--solid-background': props.isSolidBackground,
			'layout--no-background': props.hasNoBackground
		}"
	>
		<!-- <AppHeader /> -->

		<AppNav v-if="props.hasNav" />

		<main :class="{ 'is-loading': isLoading }">
			<slot></slot>

			<Loader v-if="isLoading" />
		</main>

		<p class="powered-by-wrapper" automation-tag="powered-by-label">Powered by <b>Superfeed Technologies, Inc.</b></p>
	</div>
</template>

<style lang="scss">
@import '@/scss/variables.scss';

.layout {
	display: flex;
	width: 100%;
	height: 100%;
	background-size: cover;
	background: linear-gradient(180deg, $light-blue 64%, $bg-gray 36%);

	&--solid-background {
		background: $light-blue;
	}

	&--no-background {
		background: none;
	}

	.container {
		max-width: 1280px;
		width: 100%;
		margin: 0px auto;

		@media only screen and (max-width: 1280px) {
			padding: 0px 15px;
		}
	}

	main {
		flex: 1;
		// display: flex;
		overflow: auto;
		// position: relative;

		&.is-loading {
			overflow: hidden;
		}
	}

	.powered-by-wrapper {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 10px;
		font-size: 11px;
		color: $disabled;
		background: $bg-gray;

		b {
			font-weight: bold;
		}
	}
}
</style>
