/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import Qs from 'qs'

import Response from '@/api/models/Response'
import { getFirebaseIdToken } from '@/utils/firebase'

export default class ApiService {
	protected readonly httpInstance: AxiosInstance

	protected baseHost: string = import.meta.env.VITE_API_HOST

	public constructor() {
		this.httpInstance = axios.create({
			baseURL: this.baseHost,
			paramsSerializer(params) {
				return Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
			}
		})

		this.initializeRequestInterceptor()

		this.initializeResponseInterceptor()
	}

	private initializeRequestInterceptor() {
		this.httpInstance.interceptors.request.use(this.onRequest, this.onRequestError)
	}

	private initializeResponseInterceptor() {
		this.httpInstance.interceptors.response.use(this.onResponse, this.onResponseError)
	}

	private onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
		let token

		try {
			// todo: get TP JWT for some endpoints but keep Firebase JWT for the others

			token = await getFirebaseIdToken()
		} catch (e) {
			if (import.meta.env.DEV) {
				// eslint-disable-next-line no-console
				console.error(`Failed getting token for axios request: ${e}`)
			}
		}

		if (config && config.headers && token) {
			// eslint-disable-next-line no-param-reassign
			config.headers.Authorization = `Bearer ${token}`
		}

		if (import.meta.env.DEV) {
			// logger.success(`Request to ${config.method?.toUpperCase()} ${config.baseURL}${config.url}`, config.data)
		}

		return config
	}

	private onResponse = (response: AxiosResponse): AxiosResponse => {
		if (import.meta.env.DEV) {
			// logger.success(`Response: code - ${response.status}`, response.data)
		}

		return response
	}

	private onRequestError = (error: AxiosError): Promise<AxiosError> => {
		if (import.meta.env.DEV) {
			// logger.error(`Error on request: code - ${error.code}`, error.message)
		}

		return Promise.reject(error)
	}

	private onResponseError = (error: AxiosError): Promise<AxiosError> => {
		if (import.meta.env.DEV) {
			// logger.error(`Error on response: code - ${error.code}`, error.response)
		}

		return Promise.reject(error.response)
	}

	public async apiGet<T>(path: string, config?: AxiosRequestConfig): Promise<Response<T>> {
		return (await this.httpInstance.get(path, config)).data
	}

	public async apiOptions<T>(path: string, config?: AxiosRequestConfig): Promise<Response<T>> {
		return (await this.httpInstance.options(path, config)).data
	}

	public async apiPost<T>(path: string, body?: any): Promise<Response<T>> {
		return (await this.httpInstance.post(path, body)).data
	}

	public async apiPatch<T>(path: string, data?: any): Promise<Response<T>> {
		return (await this.httpInstance.patch(path, data)).data
	}

	public async apiDelete<T>(path: string): Promise<Response<T>> {
		return (await this.httpInstance.delete(path)).data
	}

	public async apiPut<T>(path: string, body?: any): Promise<Response<T>> {
		return (await this.httpInstance.put(path, body)).data
	}
}
