<script lang="ts" setup>
import Modal from '@/components/AppModal.vue'

const emit = defineEmits<{
	(e: 'on-result', value: boolean): void
}>()

function onButtonTap(result: boolean) {
	emit('on-result', result)
}
</script>

<template>
	<Modal @on-close="onButtonTap(false)">
		<div class="sing-out-prompt">
			<p class="sing-out-prompt__message">
				You are about to sign out of your current candidate account and will be brought back to the login page
			</p>
			<div class="sing-out-prompt__buttons">
				<button class="btn btn--accept" automation-tag="confirm-logout-button" @click="onButtonTap(true)">
					Yes, Log Out
				</button>
				<button class="btn btn--decline" automation-tag="cancel-logout-button" @click="onButtonTap(false)">
					No, Stay Logged In
				</button>
			</div>
		</div>
	</Modal>
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.sing-out-prompt {
	&__message {
		max-width: 420px;
		margin: 30px auto;
		color: black;
		font-size: 20px;
		font-weight: 500;
		text-align: center;
	}

	&__buttons {
		width: 95%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0px auto;
	}

	.btn {
		width: 190px;

		&--decline {
			background: $disabled;
		}
	}
}
</style>
