import { library } from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/vue'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { createApp } from 'vue'
import { Bar } from 'vue-chartjs'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import * as VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import {
	faUser,
	faBell,
	faSignOutAlt,
	faUserPlus,
	faEye,
	faWallet,
	faFire,
	faChartPie,
	faPen,
	faChevronDown,
	faChevronUp,
	faTimes,
	faHourglass,
	faClock,
	faCalendar,
	faPlus,
	faCheck,
	faSquare,
	faLock,
	faUpload
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from '@kyvg/vue3-notification'

import AppLayout from '@/components/AppLayout.vue'

import router from '@/router'
import { store, key } from '@/store'
import clickOutside from '@/directives/click-outside'

import '@/scss/reset.scss'

import App from './App.vue'
import { initFirebase } from './utils/firebase'

const VueSelectComponent = VSelect.default

library.add(
	faUser,
	faBell,
	faSignOutAlt,
	faTimes,
	faUserPlus,
	faEye,
	faWallet,
	faFire,
	faChartPie,
	faPen,
	faChevronDown,
	faChevronUp,
	faHourglass,
	faClock,
	faCalendar,
	faPlus,
	faCheck,
	faSquare,
	faLock,
	faUpload
)

initFirebase()

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const app = createApp(App)

const sentryDsn: string | undefined = import.meta.env.VITE_SENTRY_DSN

if (sentryDsn) {
	Sentry.init({
		app,
		dsn: sentryDsn,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			// Sentry.browserProfilingIntegration(), // https://docs.sentry.io/platforms/javascript/guides/vue/profiling/#step-2-add-document-policy-js-profiling-header
			Sentry.replayIntegration(),
			Sentry.httpClientIntegration(),
			Sentry.sessionTimingIntegration()
		],

		// This option is required for capturing headers and cookies.
		sendDefaultPii: true,

		// capture 100% of transactions
		tracesSampleRate: 1.0,

		// // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/.*azurewebsites\.net/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,

		// https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/#child-components
		trackComponents: true
	})
}

app.use(store, key)

app.use(router)

app.use(Notifications)

app.directive('click-outside', clickOutside)

app.component('font-awesome-icon', FontAwesomeIcon)

app.component('VueDatePicker', VueDatePicker)

app.component('VueSelect', VueSelectComponent)

app.component('ChartBar', Bar)

app.component('AppLayout', AppLayout)

app.mount('#app')
