import { ContactList, ContactListType, ContactListSearchFilter } from '@/api/contact-lists/models/ContactList'
import { Voter } from '@/api/contact-lists/models/Voter'
import { NewVoterToList } from '@/api/contact-lists/models/NewVoterToList'
import ItemsResult from '@/api/models/ItemsResult'
import PaginationQueryGet from '@/api/models/PaginationQueryGet'
import PatchOperation from '@/api/models/PatchOperation'
import ApiService from '@/services/api-service'
import EarlyBallotSchedule, { EarlyBallotScheduleResult, NewEarlyBallotSchedule } from './models/EarlyBallotSchedule'

class ContactListsApi extends ApiService {
	async getAvailablePoliticalParties() {
		return (await this.apiGet<String[]>('/politicalParties')).Result
	}

	async addVotersToList(candidateAccountId: string, contactListId: string, contacts: NewVoterToList[]) {
		return (
			await this.apiPut<ContactList>(
				`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}/contacts`,
				{
					Contacts: contacts
				}
			)
		).Result
	}

	async createContactList(
		candidateAccountId: string,
		name: String,
		type: ContactListType,
		searchFilter?: ContactListSearchFilter
	) {
		return (
			await this.apiPost<ContactList | null>(`/candidateaccounts/${candidateAccountId}/contactlists`, {
				Name: name,
				Type: type,
				SearchFilter: searchFilter
			})
		).Result
	}

	async getContactLists(candidateAccountId: string, pagination: PaginationQueryGet) {
		return (
			await this.apiGet<ItemsResult<ContactList>>(`/candidateaccounts/${candidateAccountId}/contactlists`, {
				params: pagination
			})
		).Result
	}

	async getContactList(candidateAccountId: string, contactListId: string) {
		return (await this.apiGet<ContactList>(`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}`))
			.Result
	}

	async getContactListVoters(candidateAccountId: string, contactListId: string, pagination: PaginationQueryGet) {
		return (
			await this.apiGet<ItemsResult<Voter>>(
				`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}/contacts`,
				{
					params: pagination
				}
			)
		).Result
	}

	async updateContactList(candidateAccountId: string, contactListId: string, operations: PatchOperation[]) {
		return (
			await this.apiPatch<ContactList>(
				`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}`,
				operations
			)
		).Result
	}

	deleteContact(candidateAccountId: string, contactListId: string, contactId: string) {
		return this.apiDelete<ContactList>(
			`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}/contacts/${contactId}`
		)
	}

	deleteAllFromContactList(candidateAccountId: string, contactListId: string) {
		return this.apiDelete<ContactList>(
			`/candidateaccounts/${candidateAccountId}/contactlists/${contactListId}/contacts/all`
		)
	}

	async matchContacts(candidateAccountId: string, operationId: string) {
		return (await this.apiGet(`/candidateaccounts/${candidateAccountId}/voteruploads/${operationId}`)).Result
	}

	async resetMatchedContacts(candidateAccountId: string, operationId: string) {
		return (await this.apiDelete(`/candidateaccounts/${candidateAccountId}/voteruploads/${operationId}`)).Result
	}

	async getEarlyBallotsSchedules() {
		return (await this.apiGet<EarlyBallotScheduleResult>('/earlyballots/schedules')).Result
	}

	async getEarlyBallotsNames(stateName: string) {
		return (await this.apiOptions<string[]>(`/earlyballots/${stateName}/names`)).Result
	}

	async getAllStates() {
		return (await this.apiOptions<string[]>(`/states`)).Result
	}

	async addEarlyBallotToSchedule(payload: NewEarlyBallotSchedule) {
		return (await this.apiPost<EarlyBallotSchedule>(`/earlyballots/${payload.State}/schedules`, [payload])).Result
	}

	async removeEarlyBallotFromSchedule(stateName: string, earlyBallotScheduleId: string) {
		return (await this.apiDelete<EarlyBallotSchedule>(`/earlyballots/${stateName}/schedules/${earlyBallotScheduleId}`))
			.Result
	}

	async updateEarlyBallotInSchedule(stateName: string, earlyBallotScheduleId: string, operations: PatchOperation[]) {
		return (
			await this.apiPatch<EarlyBallotSchedule>(
				`/earlyballots/${stateName}/schedules/${earlyBallotScheduleId}`,
				operations
			)
		).Result
	}
}

export default new ContactListsApi()
