import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import type { RootState } from '@/store/index'
import { CandidateAccount } from '@/api/candidate-account/models/CandidateAccount'
import { PublicCandidateAccountApp } from '@/api/candidate-account/models/PublicCandidateAccountApp'
import ItemsResult from '@/api/models/ItemsResult'
import { UserV2, UsersSearchQuery } from '@/api/user/models/User'
import PatchOperation from '@/api/models/PatchOperation'
import {
	CandidateAccountEvent,
	EditableCandidateAccountEvent,
	NewCandidateAccountEvent
} from '@/api/candidate-account/models/CandidateAccountEvent'

export interface CandidateAccountState {
	candidateAccounts: CandidateAccount[]
	fetchedCandidateAccount: CandidateAccount | null
	selectedCandidateAccount: CandidateAccount | null
}

export enum CandidateAccountActionTypes {
	GET_ALL_CANDIDATE_ACCOUNTS = 'GET_ALL_CANDIDATE_ACCOUNTS',
	GET_PLATFORM_APPS = 'GET_PLATFORM_APPS',
	CREATE_PLATFORM_APP = 'CREATE_PLATFORM_APP',
	CREATE_CANDIDATE_ACCOUNT = 'CREATE_CANDIDATE_ACCOUNT',
	SELECT_CANDIDATE_ACCOUNT = 'SELECT_CANDIDATE_ACCOUNT',
	ADD_CANDIDATE_ACCOUNT_STAFF = 'ADD_CANDIDATE_ACCOUNT_STAFF',
	CLEAR_CANDIDATE_ACCOUNTS = 'CLEAR_CANDIDATE_ACCOUNTS',
	FETCH_CANDIDATE_ACCOUNT = 'FETCH_CANDIDATE_ACCOUNT',
	UPDATE_CANDIDATE_ACCOUNT = 'UPDATE_CANDIDATE_ACCOUNT',
	SEARCH_USERS = 'SEARCH_USERS',
	SEARCH_USERS_V2 = 'SEARCH_USERS_V2',
	UPDATE_USER = 'UPDATE_USER',
	GET_EVENTS = 'GET_EVENTS',
	ADD_EVENT = 'ADD_EVENT',
	REMOVE_EVENT = 'REMOVE_EVENT',
	UPDATE_EVENT = 'UPDATE_EVENT'
}

export enum CandidateAccountMutationTypes {
	SET_CANDIDATE_ACCOUNTS = 'SET_CANDIDATE_ACCOUNTS',
	SET_FETCHED_CANDIDATE_ACCOUNT = 'SET_FETCHED_CANDIDATE_ACCOUNT',
	SET_SELECTED_CANDIDATE_ACCOUNT = 'SET_SELECTED_CANDIDATE_ACCOUNT',
	ADD_NEW_CANDIDATE_ACCOUNT = 'ADD_NEW_CANDIDATE_ACCOUNT',
	SET_CANDIDATE_ACCOUNT_STAFF = 'SET_CANDIDATE_ACCOUNT_STAFF'
}

export type Mutations<S = CandidateAccountState> = {
	[CandidateAccountMutationTypes.SET_CANDIDATE_ACCOUNTS](moduleState: S, candidateAccounts: CandidateAccount[]): void
	[CandidateAccountMutationTypes.SET_FETCHED_CANDIDATE_ACCOUNT](moduleState: S, account: CandidateAccount): void
	[CandidateAccountMutationTypes.SET_SELECTED_CANDIDATE_ACCOUNT](
		moduleState: S,
		candidateAccount: CandidateAccount | null
	): void
	[CandidateAccountMutationTypes.ADD_NEW_CANDIDATE_ACCOUNT](moduleState: S, newCandidateAccount: CandidateAccount): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<CandidateAccountState, RootState>, 'commit'>

export interface Actions {
	[CandidateAccountActionTypes.GET_ALL_CANDIDATE_ACCOUNTS](
		{ commit, dispatch }: AugmentedActionContext,
		userEmail: string
	): Promise<boolean>
	[CandidateAccountActionTypes.GET_PLATFORM_APPS](
		_: AugmentedActionContext,
		{ candidateAccountId }: { candidateAccountId: string }
	): Promise<PublicCandidateAccountApp[]>
	[CandidateAccountActionTypes.CREATE_PLATFORM_APP](
		_: AugmentedActionContext,
		{ candidateAccountId, appName }: { candidateAccountId: string; appName: string }
	): Promise<PublicCandidateAccountApp | null>
	[CandidateAccountActionTypes.SELECT_CANDIDATE_ACCOUNT](
		{ commit }: AugmentedActionContext,
		candidateAccount: CandidateAccount
	): void
	[CandidateAccountActionTypes.CREATE_CANDIDATE_ACCOUNT](
		{ commit, rootState }: AugmentedActionContext,
		candidateAccount: CandidateAccount
	): Promise<CandidateAccount | null>
	[CandidateAccountActionTypes.CLEAR_CANDIDATE_ACCOUNTS]({ commit }: AugmentedActionContext): void
	[CandidateAccountActionTypes.FETCH_CANDIDATE_ACCOUNT](
		{ commit, rootGetters }: AugmentedActionContext,
		candidateAccount: string
	): Promise<CandidateAccount | null>
	[CandidateAccountActionTypes.UPDATE_CANDIDATE_ACCOUNT](
		{ commit, rootGetters }: AugmentedActionContext,
		{ patchOperations, accountId }: { patchOperations: PatchOperation[]; accountId: string }
	): void
	[CandidateAccountActionTypes.SEARCH_USERS](
		{ commit, dispatch }: AugmentedActionContext,
		{ accountId, phoneNumber }: { accountId: string; phoneNumber?: string }
	): void
	[CandidateAccountActionTypes.SEARCH_USERS_V2](
		{ commit, dispatch }: AugmentedActionContext,
		{ accountId, query }: { accountId: string; query?: UsersSearchQuery }
	): Promise<ItemsResult<UserV2>>
	[CandidateAccountActionTypes.UPDATE_USER](
		{ commit, dispatch }: AugmentedActionContext,
		{
			accountId,
			userId,
			operations
		}: {
			accountId: string
			userId: string
			operations: PatchOperation[]
		}
	): Promise<UserV2 | null>
	[CandidateAccountActionTypes.GET_EVENTS](
		_: AugmentedActionContext,
		{ candidateAccountId }: { candidateAccountId: string }
	): Promise<CandidateAccountEvent[]>
	[CandidateAccountActionTypes.ADD_EVENT](
		_: AugmentedActionContext,
		{ candidateAccountId, payload }: { candidateAccountId: string; payload: NewCandidateAccountEvent }
	): Promise<CandidateAccountEvent | null>
	[CandidateAccountActionTypes.REMOVE_EVENT](
		_: AugmentedActionContext,
		{ candidateAccountId, eventId }: { candidateAccountId: string; eventId: string }
	): Promise<boolean>
	[CandidateAccountActionTypes.UPDATE_EVENT](
		_: AugmentedActionContext,
		{
			candidateAccountId,
			eventId,
			payload
		}: { candidateAccountId: string; eventId: string; payload: EditableCandidateAccountEvent }
	): Promise<boolean>
}

export enum CandidateAccountGettersType {
	GET_SELECTED_CANDIDATE_ACCOUNT = 'GET_SELECTED_CANDIDATE_ACCOUNT',
	GET_CANDIDATE_ACCOUNT_BY_ID = 'GET_CANDIDATE_ACCOUNT_BY_ID'
}

export type Getters<State = CandidateAccountState> = {
	[CandidateAccountGettersType.GET_SELECTED_CANDIDATE_ACCOUNT](state: State): CandidateAccount | null
	[CandidateAccountGettersType.GET_CANDIDATE_ACCOUNT_BY_ID]: (
		state: State
	) => (candidateAccountId: string) => CandidateAccount | undefined
}

export type CandidateAccountStore<S = CandidateAccountState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
