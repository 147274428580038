import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import type { RootState } from '@/store/index'
import { Campaign, CampaignType, NewCampaign } from '@/api/campaign/models/Campaign'
import { SurveyQuestion } from '@/api/campaign/models/Survey'
import PatchOperation from '@/api/models/PatchOperation'
import { FilterCampaignType } from '@/api/campaign/Campaigns'
import ItemsResult from '@/api/models/ItemsResult'

export interface CampaignsState {
	isLoading: boolean
	campaigns: Campaign[]
	campaignsPagination: { After: string | null; IsEnd: boolean }
	activistsCursor: string
}

export enum CampaignsActionTypes {
	FETCH_ALL_DATA = 'FETCH_ALL',
	GET_CAMPAIGNS = 'GET_CAMPAIGNS',
	UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN',
	SEARCH_CAMPAIGN = 'SEARCH_CAMPAIGN',
	REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN',
	CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
	UPDATE_SURVEY = 'UPDATE_SURVEY'
}

export enum CampaignsMutationTypes {
	SET_CAMPAIGNS = 'SET_CAMPAIGNS',
	SET_CAMPAIGNS_PAGINATION = 'SET_CAMPAIGNS_PAGINATION',
	SET_ACTIVISTS_CURSOR = 'SET_ACTIVISTS_CURSOR',
	SET_LOADING_STATUS = 'SET_LOADING_STATUS'
}

export type Mutations<S = CampaignsState> = {
	[CampaignsMutationTypes.SET_CAMPAIGNS](moduleState: S, payload: { campaigns: Campaign[]; initial?: boolean }): void
	[CampaignsMutationTypes.SET_CAMPAIGNS_PAGINATION](
		moduleState: S,
		pagination: { After: string | null; IsEnd: boolean }
	): void
	[CampaignsMutationTypes.SET_ACTIVISTS_CURSOR](moduleState: S, cursor: string): void
	[CampaignsMutationTypes.SET_LOADING_STATUS](moduleState: S, status: boolean): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<CampaignsState, RootState>, 'commit'>

export interface Actions {
	[CampaignsActionTypes.FETCH_ALL_DATA](
		{ commit, dispatch }: AugmentedActionContext,
		{ accountId, campaignType }: { accountId: string; campaignType: FilterCampaignType }
	): void
	[CampaignsActionTypes.GET_CAMPAIGNS](
		{ commit, dispatch, state }: AugmentedActionContext,
		{ accountId, initial, campaignType }: { accountId: string; initial: boolean; campaignType: FilterCampaignType }
	): void
	[CampaignsActionTypes.SEARCH_CAMPAIGN](
		{ commit, dispatch, state }: AugmentedActionContext,
		{ accountId, campaignType, query }: { accountId: string; campaignType: FilterCampaignType; query: string }
	): Promise<ItemsResult<Campaign>>
	[CampaignsActionTypes.CREATE_CAMPAIGN](
		{ commit, dispatch }: AugmentedActionContext,
		{
			accountId,
			campaignType,
			campaignPayload
		}: { accountId: string; campaignType: CampaignType; campaignPayload: NewCampaign; activists?: string[] }
	): Promise<boolean>
	[CampaignsActionTypes.UPDATE_CAMPAIGN](
		{ commit, dispatch }: AugmentedActionContext,
		{
			accountId,
			campaignId,
			surveyId,
			surveyQuestions,
			operations
		}: {
			accountId: string
			campaignId: string
			surveyId?: string
			surveyQuestions?: SurveyQuestion[]
			operations: PatchOperation[]
		}
	): Promise<boolean>
	[CampaignsActionTypes.REMOVE_CAMPAIGN](
		{ commit, dispatch }: AugmentedActionContext,
		{
			accountId,
			campaignId
		}: {
			accountId: string
			campaignId: string
		}
	): Promise<boolean>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Getters<State = CampaignsState> = {}

export type CandidateAccountStore<S = CampaignsState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
