import { notify } from '@kyvg/vue3-notification'

const showErrorMessage = (error: any) => {
	notify({
		group: 'errors',
		duration: 60000,
		data: {
			error
		}
	})
}

const showSuccessMessage = (message: any) => {
	notify({
		group: 'success',
		duration: 4000,
		data: {
			message
		}
	})
}

const showWarningMessage = (message: any) => {
	notify({
		group: 'warning',
		duration: 4000,
		data: {
			message
		}
	})
}

export { showErrorMessage, showSuccessMessage, showWarningMessage }
