<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'

const props = withDefaults(defineProps<{ isActive: boolean }>(), {
	isActive: false
})

const emit = defineEmits<{
	(e: 'on-close'): void
}>()

function close() {
	emit('on-close')
}

function closeOnEsp(ev: KeyboardEvent) {
	if (props.isActive && ev.key === 'Escape') {
		close()
	}
}

onMounted(() => {
	document.addEventListener('keyup', closeOnEsp)
})

onUnmounted(() => {
	document.removeEventListener('keyup', closeOnEsp)
})
</script>

<template>
	<Teleport to="#app">
		<transition name="modal-animation">
			<div v-show="props.isActive" class="modal">
				<div class="modal__overlay" @click="close" />
				<transition name="modal-animation-content">
					<div v-show="props.isActive" class="modal__inner">
						<div class="modal__content">
							<slot />
						</div>
						<button class="modal__close" @click="close">
							<font-awesome-icon icon="times" class="icon" size="lg" />
						</button>
					</div>
				</transition>
			</div>
		</transition>
	</Teleport>
</template>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.modal-animation-enter-active,
.modal-animation-leave-active {
	transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
	opacity: 0;
}

.modal-animation-content-enter-active {
	transition: 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal-animation-content-leave-active {
	transition: 0.15s ease-out;
}
.modal-animation-content-enter-from {
	opacity: 0;
	transform: translateY(35px);
}
.modal-animation-content-leave-to {
	transform: translateY(35px);
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	max-width: 100vw;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 40;

	&__overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(33, 33, 33, 0.48);
	}

	&__inner {
		position: relative;
		max-width: 700px;
		width: auto;
		box-shadow:
			0 11px 15px -7px rgb(0 0 0 / 20%),
			0 24px 38px 3px rgb(0 0 0 / 14%),
			0 9px 46px 8px rgb(0 0 0 / 12%);
		background-color: $white;
		padding: 20px;
		border-radius: 7px;
	}

	&__close {
		position: absolute;
		right: 12px;
		top: 12px;
		padding: 0;
		background: none;
		outline: none;
		box-shadow: none;
		border: none;
		cursor: pointer;

		svg {
			color: $dark;
			transition: color 0.3s ease-out;
		}

		&:hover {
			svg {
				color: $red;
			}
		}
	}
}
</style>
