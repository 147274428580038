/* eslint-disable no-console */
class LogService {
	private colors: Record<string, string> = {
		warn: '#e1db29',
		error: '#db352c',
		success: '#17e37b',
		default: '#24bac1'
	}

	// eslint-disable-next-line class-methods-use-this
	private formateDate = (): string => {
		return new Date(Date.now()).toUTCString()
	}

	private formateMessage = (message: string, logData: any = null): string => {
		const simpleMessage = `| ${this.formateDate()} | ${message} |`
		const detailedMessage = `${message} \n ${JSON.stringify(logData)}`

		return logData ? detailedMessage : simpleMessage
	}

	// eslint-disable-next-line default-param-last
	private createLog(logType: string = 'default', logMessage: string, logData: any) {
		return console.log(
			`%c${this.formateMessage(logMessage, logData)}`,
			`
				color: ${this.colors[logType]};
				font-family: Courier; font-size: 13px;
				font-weight: bold; padding: 4px;
			`
		)
	}

	public warn(logMessage: string, logData?: any) {
		this.createLog('warn', logMessage, logData)
	}

	public error(logMessage: string, logData?: any) {
		this.createLog('error', logMessage, logData)
	}

	public success(logMessage: string, logData?: any) {
		this.createLog('success', logMessage, logData)
	}

	public log(logMessage: string, logData?: any) {
		this.createLog('default', logMessage, logData)
	}
}

const logger = new LogService()

export default logger
