import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import type { RootState } from '@/store/index'
import { showErrorMessage } from '@/utils/toast-message'

import StatisticsApi from '@/api/statistics'

import {
	StatisticsState,
	Actions,
	StatisticsActionTypes,
	StatisticsMutationTypes,
	Mutations,
	Getters,
	StatisticsGettersType
} from './types'

export const statisticsState: StatisticsState = {
	isStatisticsLoading: false,
	isMetricsLoading: false,
	metricsItems: []
}

const actions: ActionTree<StatisticsState, RootState> & Actions = {
	async [StatisticsActionTypes.GENERATE_STATISTICS_LINK]({ commit, dispatch }, { accountId, from, to }) {
		try {
			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, true)

			dispatch(StatisticsActionTypes.CLEAR_STATISTICS)

			await StatisticsApi.generateStatisticsLink(accountId, from, to)

			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, false)
		} catch (error) {
			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, false)

			showErrorMessage(error)
		}
	},

	async [StatisticsActionTypes.GET_STATISTICS_LINK]({ commit }, { accountId, fileName }) {
		try {
			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, true)

			const response = await StatisticsApi.getStatisticsLink(accountId, fileName)

			const blob = new Blob([response.data], { type: 'text/csv' })

			const url = window.URL.createObjectURL(blob)

			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, false)

			return {
				fileName,
				fileLink: url
			}
		} catch (error) {
			commit(StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS, false)

			showErrorMessage(error)

			return null
		}
	},

	async [StatisticsActionTypes.GET_METRICS]({ commit }, { accountId, type, from, campaignId = '', to = '' }) {
		try {
			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, true)

			const metrics = await StatisticsApi.getMetrics(accountId, type, from, campaignId, to)

			commit(StatisticsMutationTypes.SET_METRICS, metrics)

			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, false)
		} catch (error) {
			commit(StatisticsMutationTypes.SET_METRICS_LOADING_STATUS, false)

			showErrorMessage(error)
		}
	}
}

const mutations: MutationTree<StatisticsState> & Mutations = {
	[StatisticsMutationTypes.SET_METRICS](moduleState, metrics) {
		moduleState.metricsItems = metrics
	},

	[StatisticsMutationTypes.SET_STATISTICS_LOADING_STATUS](moduleState, status) {
		moduleState.isStatisticsLoading = status
	},

	[StatisticsMutationTypes.SET_METRICS_LOADING_STATUS](moduleState, status) {
		moduleState.isMetricsLoading = status
	}
}

export const getters: GetterTree<StatisticsState, RootState> & Getters = {
	[StatisticsGettersType.GET_TOTAL_SUCCESS_VOTERS](moduleState) {
		return moduleState.metricsItems.reduce((total, item) => total + item.SuccessfulContacts, 0)
	},
	[StatisticsGettersType.GET_ACTIVIST_NUMBER](moduleState) {
		const uniqueVoters = new Set(moduleState.metricsItems.map((item) => item.UserId))

		return uniqueVoters.size
	}
}

export const store: Module<StatisticsState, RootState> = {
	state: statisticsState,
	getters,
	mutations,
	actions
}
