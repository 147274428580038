import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import * as signalR from '@microsoft/signalr'

import { ContactListsApi, VoterDatabasesApi } from '@/api/contact-lists'
import EventContactsApi from '@/api/contact-lists/EventContacts'
import { NewVoterToList } from '@/api/contact-lists/models/NewVoterToList'
import logger from '@/services/log-service'
import type { RootState } from '@/store/index'
import { showErrorMessage } from '@/utils/toast-message'

import { getFirebaseIdToken } from '@/utils/firebase'
import { ContactMatchingEventsTypes, ContactMatchingSignalREvent } from '@/api/contact-lists/models/ContactList'
import EarlyBallotSchedule, { EarlyBallotScheduleResult } from '@/api/contact-lists/models/EarlyBallotSchedule'
import PatchOperation from '@/api/models/PatchOperation'
import {
	ContactListsState,
	Actions,
	ContactListsActionTypes,
	ContactListsMutationTypes,
	Mutations,
	Getters
} from './types'

export const contactListsState: ContactListsState = {
	contactLists: [],
	signalRConnection: null,
	matchingProgress: 0,
	isMatching: false,
	totalContactsMatched: 0,
	matchingOperationId: '',
	matchedContactListId: '',
	contactListsCursor: { After: '', Before: '' },
	voterDatabases: []
}

const actions: ActionTree<ContactListsState, RootState> & Actions = {
	async [ContactListsActionTypes.ADD_VOTERS_TO_LIST](
		// eslint-disable-next-line no-empty-pattern
		{},
		payload: { candidateAccountId: string; contactListId: string; contacts: NewVoterToList[] }
	) {
		try {
			return await ContactListsApi.addVotersToList(payload.candidateAccountId, payload.contactListId, payload.contacts)
		} catch (error) {
			logger.error('Something went wrong while searching in voter databases', error)

			showErrorMessage(error)

			return null
		}
	},

	async [ContactListsActionTypes.CREATE_CONTACT_LIST](
		{ commit },
		{ candidateAccountId, contactListName, contactListType, searchFilter }
	) {
		try {
			const newContactList = await ContactListsApi.createContactList(
				candidateAccountId,
				contactListName,
				contactListType,
				searchFilter
			)

			if (newContactList) {
				commit(ContactListsMutationTypes.ADD_NEW_CONTACT_LIST, newContactList)
			}

			return newContactList
		} catch (error) {
			logger.error('Something went wrong while creating a new candidate', error)

			showErrorMessage(error)

			return null
		}
	},

	async [ContactListsActionTypes.GET_CONTACT_LISTS]({ commit, state }, candidateAccountId) {
		try {
			const result = await ContactListsApi.getContactLists(candidateAccountId, {
				After: state.contactListsCursor.After
			})

			commit(ContactListsMutationTypes.SET_CONTACT_LISTS, {
				contactLists: result.Items,
				initial: !state.contactListsCursor.After
			})

			commit(ContactListsMutationTypes.SET_CONTACT_LISTS_CURSOR, result.Cursors)

			return result
		} catch (error) {
			logger.error('Something went wrong while fetching contact lists', error)

			showErrorMessage(error)

			return { Items: [], Cursors: {}, TotalCount: 0 }
		}
	},

	async [ContactListsActionTypes.GET_CONTACT_LIST_BY_ID](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ candidateAccountId, contactListId }
	) {
		try {
			return await ContactListsApi.getContactList(candidateAccountId, contactListId)
		} catch (error) {
			logger.error('Something went wrong while fetching contact list', error)

			showErrorMessage(error)

			return null
		}
	},

	async [ContactListsActionTypes.GET_ALL_VOTER_DATABASES]({ commit }) {
		try {
			const databases = await VoterDatabasesApi.getVoterDatabases()

			commit(ContactListsMutationTypes.SET_VOTER_DATABASES, databases)
		} catch (error) {
			logger.error('Something went wrong while fetching voter databases', error)

			showErrorMessage(error)
		}
	},

	async [ContactListsActionTypes.GET_CONTACT_LIST_VOTERS](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ candidateAccountId, contactListId, pagination }
	) {
		try {
			return await ContactListsApi.getContactListVoters(candidateAccountId, contactListId, pagination)
		} catch (error) {
			logger.error('Something went wrong while fetching contact list voters', error)

			showErrorMessage(error)

			return { Items: [], Cursors: { After: '', Before: '' }, TotalCount: 0 }
		}
	},

	async [ContactListsActionTypes.SEARCH_IN_VOTER_DATABASE](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ databaseId, query }
	) {
		try {
			return await VoterDatabasesApi.searchInVoterDatabase(databaseId, query)
		} catch (error) {
			logger.error('Something went wrong while searching in voter databases', error)

			showErrorMessage(error)

			return { Items: [], Cursors: { After: '', Before: '' }, TotalCount: 0 }
		}
	},

	async [ContactListsActionTypes.GET_EVENT_CONTACTS](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ candidateAccountId }
	) {
		try {
			return await EventContactsApi.getAllContacts(candidateAccountId)
		} catch (error) {
			logger.error('Something went wrong while fetching contacts', error)

			showErrorMessage(error)

			return { Items: [], Cursors: { After: '', Before: '' }, TotalCount: 0 }
		}
	},

	async [ContactListsActionTypes.SEARCH_IN_VOTER_DATABASE_AND_RETURN_ALL](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ databaseId, query }
	) {
		try {
			return await VoterDatabasesApi.searchInVoterDatabaseAndReturnAllResults(databaseId, query)
		} catch (error) {
			logger.error('Something went wrong while searching in voter databases', error)

			showErrorMessage(error)

			return []
		}
	},

	async [ContactListsActionTypes.UPDATE_CONTACT_LIST](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ candidateAccountId, contactListId, operations }
	) {
		try {
			return await ContactListsApi.updateContactList(candidateAccountId, contactListId, operations)
		} catch (error) {
			logger.error('Something went wrong while searching in voter databases', error)

			showErrorMessage(error)

			return null
		}
	},

	async [ContactListsActionTypes.DELETE_CONTACTS](
		// eslint-disable-next-line no-empty-pattern
		{},
		{ candidateAccountId, contactListId, contactIds, deleteAll }
	) {
		try {
			if (deleteAll) {
				await ContactListsApi.deleteAllFromContactList(candidateAccountId, contactListId)
			} else if (contactIds) {
				await Promise.all(
					contactIds.map((contactId) => ContactListsApi.deleteContact(candidateAccountId, contactListId, contactId))
				)
			}

			return true
		} catch (error) {
			logger.error('Something went wrong while searching in voter databases', error)

			showErrorMessage(error)

			return false
		}
	},

	async [ContactListsActionTypes.GET_AVAILABLE_POLITICAL_PARTIES]() {
		try {
			return ContactListsApi.getAvailablePoliticalParties()
		} catch (error) {
			logger.error('Something went wrong while fetching available political parties', error)

			showErrorMessage(error)

			return []
		}
	},

	async [ContactListsActionTypes.INIT_SIGNALR_CONNECTION]({ commit }, { candidateAccountId }) {
		const signalRConnection = new signalR.HubConnectionBuilder()
			.withUrl(`${import.meta.env.VITE_API_NOTIFICATIONS_HOST}/candidateaccounts/${candidateAccountId}`, {
				withCredentials: false,
				async accessTokenFactory() {
					const token = await getFirebaseIdToken()

					return `${token}`
				}
			})
			.build()

		commit(ContactListsMutationTypes.SET_SIGNALR_CONNECTION, signalRConnection)

		await signalRConnection.start()
	},

	async [ContactListsActionTypes.MATCH_CONTACTS_FROM_CSV]({ commit, state }, { uploadedCSVUrl }) {
		commit(ContactListsMutationTypes.SET_MATCHING_STATUS, true)

		const regExp = /[^/]*$/
		const operationId = regExp.exec(uploadedCSVUrl)?.[0]

		if (operationId) {
			commit(ContactListsMutationTypes.SET_MATCHING_OPERATION_ID, operationId)

			if (state.signalRConnection !== null) {
				state.signalRConnection.on('onNewNotification', (event: ContactMatchingSignalREvent) => {
					if (event.Type === ContactMatchingEventsTypes.VoterMatchProgressEvent) {
						// Sometimes events with bigger Percentage value come earlier
						if (state.matchingProgress > event.Data.Percentage) return

						commit(ContactListsMutationTypes.SET_MATCHING_PROGRESS, event.Data.Percentage)

						commit(ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER, event.Data.ProcessedContacts)

						if (event.Data.Percentage === 100) {
							commit(ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER, event.Data.TotalContacts)

							commit(ContactListsMutationTypes.SET_MATCHING_STATUS, false)

							commit(ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID, operationId)
						}
					}

					if (event.Type === ContactMatchingEventsTypes.VoterMatchCompletedEvent) {
						commit(ContactListsMutationTypes.SET_MATCHING_PROGRESS, 100)

						commit(ContactListsMutationTypes.SET_MATCHING_STATUS, false)

						commit(ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID, operationId)

						setTimeout(() => {
							state.signalRConnection?.off('onNewNotification')

							state.signalRConnection?.stop()
						}, 1000)
					}
				})
			}
		}
	},

	[ContactListsActionTypes.GET_MATCHED_DATA_FROM_LOCAL_STORAGE]({ commit }, data) {
		commit(ContactListsMutationTypes.SET_MATCHING_PROGRESS, 100)

		commit(ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER, data.totalContactsMatched)

		commit(ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID, data.operationId)

		commit(ContactListsMutationTypes.SET_MATCHING_OPERATION_ID, data.operationId)
	},

	[ContactListsActionTypes.RESET_CONTACT_MATCHING]({ state, commit, rootState }) {
		if (state.signalRConnection) {
			state.signalRConnection.off('onNewNotification')

			state.signalRConnection.stop()

			if (state.matchedContactListId.length === 0 && state.matchingOperationId.length > 0) {
				ContactListsApi.resetMatchedContacts(
					rootState.candidateAccount.selectedCandidateAccount?.Id ?? '',
					state.matchingOperationId
				)
			}
		}

		commit(ContactListsMutationTypes.SET_MATCHING_PROGRESS, 0)

		commit(ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER, 0)

		commit(ContactListsMutationTypes.SET_MATCHING_STATUS, false)

		commit(ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID, '')

		commit(ContactListsMutationTypes.SET_MATCHING_OPERATION_ID, '')
	},

	async [ContactListsActionTypes.GET_EARLY_BALLOTS_SCHEDULES]() {
		try {
			return await ContactListsApi.getEarlyBallotsSchedules()
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return {} as EarlyBallotScheduleResult
		}
	},

	async [ContactListsActionTypes.GET_EARLY_BALLOTS_NAMES](_, stateName) {
		try {
			return await ContactListsApi.getEarlyBallotsNames(stateName)
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return Promise.resolve([])
		}
	},

	async [ContactListsActionTypes.GET_STATES]() {
		try {
			return await ContactListsApi.getAllStates()
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return Promise.resolve([])
		}
	},

	async [ContactListsActionTypes.ADD_EARLY_BALLOT_TO_SCHEDULE](_, payload) {
		try {
			return await ContactListsApi.addEarlyBallotToSchedule(payload)
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return Promise.resolve(null)
		}
	},

	async [ContactListsActionTypes.REMOVE_EARLY_BALLOT_FROM_SCHEDULE](_, { stateName, scheduleId }) {
		try {
			await ContactListsApi.removeEarlyBallotFromSchedule(stateName, scheduleId)

			return true
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return Promise.resolve(false)
		}
	},

	async [ContactListsActionTypes.EDIT_EARLY_BALLOT_IN_SCHEDULE](_, { stateName, scheduleId, payload }) {
		try {
			const operations: PatchOperation[] = []

			Object.keys(payload).forEach((key) => {
				if (key === 'State') {
					return
				}

				operations.push({
					path: `/${key}`,
					value: payload[key as keyof EarlyBallotSchedule],
					op: 'replace'
				})
			})

			await ContactListsApi.updateEarlyBallotInSchedule(stateName, scheduleId, operations)

			return true
		} catch (error) {
			logger.error('Something went wrong while fetching early ballots schedule', error)

			showErrorMessage(error)

			return Promise.resolve(false)
		}
	}
}

const mutations: MutationTree<ContactListsState> & Mutations = {
	[ContactListsMutationTypes.ADD_NEW_CONTACT_LIST](moduleState, newCandidateList) {
		moduleState.contactLists.push(newCandidateList)
	},

	[ContactListsMutationTypes.SET_CONTACT_LISTS](moduleState, payload) {
		if (payload.initial) {
			moduleState.contactLists = payload.contactLists
		} else {
			moduleState.contactLists.push(...payload.contactLists)
		}
	},

	[ContactListsMutationTypes.SET_CONTACT_LISTS_CURSOR](moduleState, cursor) {
		moduleState.contactListsCursor.After = cursor.After
		moduleState.contactListsCursor.Before = cursor.Before
	},

	[ContactListsMutationTypes.SET_VOTER_DATABASES](moduleState, databases) {
		moduleState.voterDatabases = databases
	},

	[ContactListsMutationTypes.SET_SIGNALR_CONNECTION](moduleState, connection) {
		moduleState.signalRConnection = connection
	},

	[ContactListsMutationTypes.SET_MATCHING_PROGRESS](moduleState, progress) {
		moduleState.matchingProgress = progress
	},

	[ContactListsMutationTypes.SET_MATCHING_STATUS](moduleState, status) {
		moduleState.isMatching = status
	},

	[ContactListsMutationTypes.SET_MATCHING_CONTACT_LIST_ID](moduleState, id) {
		moduleState.matchedContactListId = id
	},

	[ContactListsMutationTypes.SET_MATCHING_OPERATION_ID](moduleState, id) {
		moduleState.matchingOperationId = id
	},

	[ContactListsMutationTypes.SET_MATCHED_CONTACTS_NUMBER](moduleState, contactsNumber) {
		moduleState.totalContactsMatched = contactsNumber
	}
}

export const getters: GetterTree<ContactListsState, RootState> & Getters = {
	isContactListsAllLoaded: (moduleState) => {
		return (
			(moduleState.contactListsCursor.After.length === 0 && moduleState.contactListsCursor.Before.length > 0) ||
			(moduleState.contactListsCursor.After.length === 0 && moduleState.contactListsCursor.Before.length === 0)
		)
	}
}

export const store: Module<ContactListsState, RootState> = {
	state: contactListsState,
	getters,
	mutations,
	actions
}
