import { CandidateAccount } from '@/api/candidate-account/models/CandidateAccount'
import PatchOperation from '@/api/models/PatchOperation'
import ApiService from '@/services/api-service'
import { PublicCandidateAccountApp } from './models/PublicCandidateAccountApp'
import { CandidateAccountEvent, NewCandidateAccountEvent } from './models/CandidateAccountEvent'

class CandidateAccountsApi extends ApiService {
	async getCandidateAccount(userId: string, candidateAccountId: string) {
		return (await this.apiGet<CandidateAccount>(`/users/${userId}/candidateaccounts/${candidateAccountId}`)).Result
	}

	async getUserCandidateAccounts(userEmail: string) {
		return (
			await this.apiOptions<CandidateAccount[]>(`/candidateaccounts`, {
				data: { email: userEmail },
				headers: { 'X-API-KEY': import.meta.env.VITE_API_OPTIONS_TOKEN }
			})
		).Result
	}

	async createUserCandidateAccount(userId: string, candidateAccount: CandidateAccount) {
		return (
			await this.apiPost<CandidateAccount>(`/users/${userId}/candidateaccounts?version=2.0`, {
				...candidateAccount
			})
		).Result
	}

	async updateUserCandidateAccount(userId: string, candidateAccountId: string, operations: PatchOperation[]) {
		return (
			await this.apiPatch<CandidateAccount>(`/users/${userId}/candidateaccounts/${candidateAccountId}`, operations)
		).Result
	}

	async deleteUserCandidateAccount(userId: string, candidateAccountId: string) {
		return (await this.apiDelete<CandidateAccount>(`/users/${userId}/candidateaccounts/${candidateAccountId}`)).Result
	}

	async getPlatformApps(candidateAccountId: string) {
		return (await this.apiGet<{ Items: PublicCandidateAccountApp[] }>(`/candidateaccounts/${candidateAccountId}/apps`))
			.Result.Items
	}

	async createPlatformApp(candidateAccount: string, name: string) {
		return (
			await this.apiPost<PublicCandidateAccountApp>(`/candidateaccounts/${candidateAccount}/apps`, {
				name
			})
		).Result
	}

	async getAllEvents(candidateAccountId: string) {
		return (await this.apiGet<CandidateAccountEvent[]>(`/candidateaccounts/${candidateAccountId}/events`)).Result
	}

	async addEvent(candidateAccount: string, payload: NewCandidateAccountEvent) {
		return (await this.apiPost<CandidateAccountEvent>(`/candidateaccounts/${candidateAccount}/events`, payload)).Result
	}

	async removeEvent(candidateAccountId: string, eventId: string) {
		return (await this.apiDelete(`/candidateaccounts/${candidateAccountId}/events/${eventId}`)).Result
	}

	async updateEvent(candidateAccountId: string, eventId: string, operations: PatchOperation[]) {
		return (
			await this.apiPatch<CandidateAccountEvent>(
				`/candidateaccounts/${candidateAccountId}/events/${eventId}`,
				operations
			)
		).Result
	}
}

export default new CandidateAccountsApi()
