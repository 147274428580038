enum AppRoutes {
	dashboard = 'dashboard',
	addStaff = 'add-staff',
	eventContacts = 'event-contacts',
	voterContactLists = 'voter-contact-lists',
	campaignsService = 'campaigns-service',
	customCampaign = 'custom-campaign',
	signUp = 'sign-up',
	login = 'login',
	resetPassword = 'reset-password',
	notFound = '404',
	statistics = 'statistics',
	downloadStatisticsReport = 'downloadStatisticsReport',
	activismTiles = 'activism-tiles',
	platformApps = 'platform-apps',
	createPlatformApp = 'create-platform-app',
	manageUsers = 'manage-users',
	earlyBallots = 'early-ballots',
	events = 'events'
}

export default AppRoutes
