import { CandidateAccountStaff } from '@/api/candidate-account/models/CandidateAccount'
import ApiService from '@/services/api-service'
import { CandidateAccountUser, UserV2, UsersSearchQuery } from '@/api/user/models/User'
import ItemsResult from '../models/ItemsResult'
import PatchOperation from '../models/PatchOperation'

class CandidateAccountUsersApi extends ApiService {
	async searchUsers(candidateAccountId: string, phoneNumber?: string) {
		return (
			await this.apiGet<CandidateAccountUser[]>(`/candidateaccounts/${candidateAccountId}/users`, {
				params: { phoneNumber }
			})
		).Result
	}

	async searchUsersV2(candidateAccountId: string, query?: UsersSearchQuery) {
		return (
			await this.apiGet<ItemsResult<UserV2>>(`/candidateaccounts/${candidateAccountId}/users?version=2`, {
				params: query
			})
		).Result
	}

	async updateUser(candidateAccountId: string, userId: string, operations: PatchOperation[]) {
		return (await this.apiPatch<UserV2>(`/candidateaccounts/${candidateAccountId}/users/${userId}`, operations)).Result
	}

	async addStaffForCandidateAccount(candidateAccountId: string, staff: CandidateAccountStaff) {
		return (await this.apiPost<CandidateAccountStaff>(`/candidateaccounts/${candidateAccountId}/users`, staff)).Result
	}

	async deleteStaffFromCandidateAccount(candidateAccountId: string, staffId: string) {
		return (await this.apiDelete<CandidateAccountStaff>(`/candidateaccounts/${candidateAccountId}/users/${staffId}`))
			.Result
	}
}

export default new CandidateAccountUsersApi()
