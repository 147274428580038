import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'
import { AuthError, Unsubscribe } from 'firebase/auth'

import User from '@/api/user/models/User'
import type { RootState } from '@/store/index'
import { UserRole } from '@/api/user/models/UserRole'
import { CandidateAccount } from '@/api/candidate-account/models/CandidateAccount'

export interface AuthState {
	user: null | User
	userRole: UserRole | null
	isAuthorized: boolean
	authErrorCode: string
	onAuthStateChangedUnsubscribe: Unsubscribe | null
}

export enum CustomAuthErrorCodes {
	CANDIDATES_NOT_FOUND = 'candidates-not-found',
	NO_FIREBASE_APP = 'app/no-app'
}

export enum CustomAuthErrorMessages {
	UserNotFound = 'There is no such user. Please make sure that you have an account.',
	WrongPassword = 'Your password is incorrect. Please make sure you type the correct password.',
	TooManyRequests = 'You have made too many requests. Please try once again later.',
	CandidatesNotFound = 'There are no candidate accounts found. Please make sure you have at least one account associated with this email.',
	ExpiredActionCode = 'This link is expired. Please try reseting your password with "Forgot Password" link on the Login page.',
	NotSpecified = 'No error message is specified',
	NoFirebaseApp = 'It seems your account is not properly set up, please contact our support team.'
}

export interface UserPasswordResetParams {
	code: string
	password: string
	firebaseAppName: string
}

export enum AuthActionTypes {
	LOGIN = 'LOGIN',
	SEND_RESET_PASSWORD_LINK = 'SEND_RESET_PASSWORD_LINK',
	HANDLE_FIREBASE_ERROR = 'HANDLE_FIREBASE_ERROR',
	LOG_OUT = 'LOG_OUT',
	CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION',
	RESET_PASSWORD = 'RESET_PASSWORD',
	GET_USER_INFO = 'GET_USER_INFO',
	GET_USER_ROLE = 'GET_USER_ROLE'
}

export enum AuthMutationTypes {
	SET_USER = 'SET_USER',
	SET_USER_ROLE = 'SET_USER_ROLE',
	SET_AUTHORIZED_STATUS = 'SET_AUTHORIZED_STATUS',
	SET_AUTH_ERROR_CODE = 'SET_AUTH_ERROR_CODE',
	SET_ON_AUTH_STATE_CHANGED_UNSUBSCRIBE = 'SET_ON_AUTH_STATE_CHANGED_UNSUBSCRIBE'
}

export type Mutations<S = AuthState> = {
	[AuthMutationTypes.SET_USER](moduleState: S, user: User | null): void
	[AuthMutationTypes.SET_USER_ROLE](moduleState: S, role: UserRole): void
	[AuthMutationTypes.SET_AUTHORIZED_STATUS](moduleState: S, status: boolean): void
	[AuthMutationTypes.SET_AUTH_ERROR_CODE](moduleState: S, code: string): void
	[AuthMutationTypes.SET_ON_AUTH_STATE_CHANGED_UNSUBSCRIBE](
		moduleState: S,
		onAuthStateChangedUnsubscribe: Unsubscribe | null
	): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<AuthState, RootState>, 'commit'>

export interface Actions {
	[AuthActionTypes.LOGIN](
		{ dispatch }: AugmentedActionContext,
		{ email, password, candidateAccount }: { email: string; password: string; candidateAccount: CandidateAccount }
	): void
	[AuthActionTypes.LOG_OUT]({ dispatch }: AugmentedActionContext): void
	[AuthActionTypes.SEND_RESET_PASSWORD_LINK](
		{ dispatch, commit }: AugmentedActionContext,
		{ email, candidateAccount }: { email: string; candidateAccount: CandidateAccount }
	): void
	[AuthActionTypes.RESET_PASSWORD](
		{ dispatch }: AugmentedActionContext,
		{ code, password, firebaseAppName }: UserPasswordResetParams
	): Promise<boolean>
	[AuthActionTypes.HANDLE_FIREBASE_ERROR]({ commit }: AugmentedActionContext, error: AuthError): void
	[AuthActionTypes.CHECK_AUTHENTICATION]({ commit, dispatch, state }: AugmentedActionContext): void
	[AuthActionTypes.GET_USER_INFO]({ commit }: AugmentedActionContext): void
	[AuthActionTypes.GET_USER_ROLE]({ commit }: AugmentedActionContext, userId: string): void
}

export enum AuthGettersType {
	GET_AUTH_STATUS = 'GET_AUTH_STATUS',
	GET_USER_NAME = 'GET_USER_NAME',
	GET_FULL_USER_NAME = 'GET_FULL_USER_NAME',
	GET_CURRENT_USER_ID = 'GET_CURRENT_USER_ID'
}

export type Getters<State = AuthState> = {
	[AuthGettersType.GET_AUTH_STATUS](state: State): boolean
	[AuthGettersType.GET_USER_NAME](state: State): string | null
	[AuthGettersType.GET_FULL_USER_NAME](state: State): string | null
	[AuthGettersType.GET_CURRENT_USER_ID](state: State): string | null
}

export type AuthStore<S = AuthState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
