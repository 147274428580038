import { Upload } from 'tus-js-client'
import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import type { RootState } from '@/store/index'
import logger from '@/services/log-service'
import { getFirebaseIdToken } from '@/utils/firebase'

import {
	TusFileUploadingState,
	Actions,
	TusFileUploadingActionTypes,
	TusFileUploadingMutationTypes,
	TusFileUploadingGettersType,
	Mutations,
	Getters
} from './types'
import { ContactListsActionTypes } from '../contact-lists/types'

export const mState: TusFileUploadingState = {
	uploadInstance: null,
	uploadedFileUrl: '',
	uploadedFileInstance: null,
	uploadProgressValue: 0,
	isUploading: false
}

const actions: ActionTree<TusFileUploadingState, RootState> & Actions = {
	async [TusFileUploadingActionTypes.INIT_UPLOAD_INSTANCE]({ commit }, { file, endpoint, onSuccess }) {
		commit(TusFileUploadingMutationTypes.SET_UPLOADING_STATUS, true)

		const uploadInstance = new Upload(file, {
			endpoint,
			headers: {
				Authorization: `Bearer ${await getFirebaseIdToken()}`,
				'Access-Control-Allow-Headers': '*'
			},
			retryDelays: [0, 3000, 5000, 10000],
			metadata: {
				filename: file?.name,
				filetype: file?.type
			},
			onError() {
				commit(TusFileUploadingMutationTypes.SET_UPLOADING_STATUS, false)
			},
			onProgress(bytesUploaded, bytesTotal) {
				const percentage: string = ((bytesUploaded / bytesTotal) * 100).toFixed()

				commit(TusFileUploadingMutationTypes.SET_UPLOAD_PROGRESS, +percentage)
			},
			onSuccess() {
				commit(TusFileUploadingMutationTypes.SET_UPLOADED_FILE_URL, uploadInstance.url ?? '')

				commit(TusFileUploadingMutationTypes.SET_UPLOADED_FILE_URL, uploadInstance.url ?? '')

				if (onSuccess) {
					onSuccess()
				}

				commit(TusFileUploadingMutationTypes.SET_UPLOADING_STATUS, false)
			}
		})

		commit(TusFileUploadingMutationTypes.SET_UPLOAD_INSTANCE, uploadInstance)

		uploadInstance.start()
	},

	[TusFileUploadingActionTypes.UPLOAD_IMAGE]({ dispatch }, image) {
		try {
			dispatch(TusFileUploadingActionTypes.INIT_UPLOAD_INSTANCE, {
				file: image,
				endpoint: `${import.meta.env.VITE_API_MEDIA_HOST}/media`
			})
		} catch (error) {
			logger.error('Something went wrong while uploading an image', error)
		}
	},

	async [TusFileUploadingActionTypes.UPLOAD_CSV]({ state, dispatch, commit }, { file, candidateAccountId }) {
		commit(TusFileUploadingMutationTypes.SET_UPLOADING_STATUS, true)

		await dispatch(ContactListsActionTypes.INIT_SIGNALR_CONNECTION, { candidateAccountId })

		dispatch(TusFileUploadingActionTypes.INIT_UPLOAD_INSTANCE, {
			file,
			endpoint: `${import.meta.env.VITE_API_CONTACT_LISTS_HOST}/candidateAccounts/${candidateAccountId}/voteruploads`,
			onSuccess: () => {
				dispatch(ContactListsActionTypes.MATCH_CONTACTS_FROM_CSV, {
					candidateAccountId,
					uploadedCSVUrl: state.uploadedFileUrl
				})
			}
		})
	},

	[TusFileUploadingActionTypes.RESET_UPLOADED_CSV]({ dispatch }) {
		dispatch(TusFileUploadingActionTypes.RESET_UPLOAD)
		dispatch(ContactListsActionTypes.RESET_CONTACT_MATCHING)
	},

	[TusFileUploadingActionTypes.RESET_UPLOAD]({ commit, state }) {
		commit(TusFileUploadingMutationTypes.SET_UPLOAD_PROGRESS, 0)
		commit(TusFileUploadingMutationTypes.SET_UPLOADING_STATUS, false)
		commit(TusFileUploadingMutationTypes.SET_UPLOADED_FILE_URL, '')

		if (state.uploadInstance) {
			state.uploadInstance.abort()
		}

		commit(TusFileUploadingMutationTypes.SET_UPLOAD_INSTANCE, null)
	}
}

const mutations: MutationTree<TusFileUploadingState> & Mutations = {
	[TusFileUploadingMutationTypes.SET_UPLOAD_INSTANCE](moduleState, instance) {
		moduleState.uploadInstance = instance
	},

	[TusFileUploadingMutationTypes.SET_UPLOAD_PROGRESS](moduleState, progress) {
		moduleState.uploadProgressValue = progress
	},

	[TusFileUploadingMutationTypes.SET_UPLOADING_STATUS](moduleState, status) {
		moduleState.isUploading = status
	},

	[TusFileUploadingMutationTypes.SET_UPLOADED_FILE_URL](moduleState, url) {
		moduleState.uploadedFileUrl = url
	}
}

export const getters: GetterTree<TusFileUploadingState, RootState> & Getters = {
	[TusFileUploadingGettersType.GET_UPLOAD_INSTANCE](moduleState) {
		return moduleState.uploadInstance
	}
}

export const store: Module<TusFileUploadingState, RootState> = {
	state: mState,
	getters,
	mutations,
	actions
}
