import ApiService from '@/services/api-service'
import { Campaign, CampaignType, NewCampaign } from '@/api/campaign/models/Campaign'
import { Survey } from '@/api/campaign/models/Survey'
import PatchOperation from '@/api/models/PatchOperation'
import PaginationQueryGet from '@/api/models/PaginationQueryGet'
import Qs from 'qs'
import ItemsResult from '../models/ItemsResult'

export enum FilterCampaignType {
	Walking = 'Walking',
	Phone = 'Phone',
	Postcard = 'Postcard',
	Sms = 'Sms'
}

export enum FilterCampaignState {
	Active = 'Active',
	Created = 'Created',
	Completed = 'Completed'
}

interface CampaignsFilter extends PaginationQueryGet {
	State?: FilterCampaignState
	Types?: FilterCampaignType[]
}

class CampaignsApi extends ApiService {
	async getCampaigns(candidateAccountId: string, pagination?: CampaignsFilter) {
		return (
			await this.apiGet<{ Items: Campaign[]; Cursors: { After: string; Before: string } }>(
				`/candidateaccounts/${candidateAccountId}/campaigns`,
				{
					params: {
						version: '2.0',
						...pagination
					},
					paramsSerializer(params) {
						return Qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true, encode: false })
					}
				}
			)
		).Result
	}

	async createCampaign(
		candidateAccountId: string,
		campaignType: CampaignType,
		campaignPayload: NewCampaign,
		assignedActivists: string[]
	) {
		return (
			await this.apiPost<Campaign>(`/candidateaccounts/${candidateAccountId}/campaigns?version=2.0`, {
				Type: campaignType,
				Payload: {
					...campaignPayload,
					AssignedActivists: assignedActivists
				}
			})
		).Result
	}

	async searchCampaign(candidateAccountId: string, campaignType: FilterCampaignType, query: string) {
		return (
			await this.apiGet<ItemsResult<Campaign>>(`/candidateaccounts/${candidateAccountId}/campaigns?version=2`, {
				params: {
					Type: [campaignType],
					Search: query
				}
			})
		).Result
	}

	async updateCampaign(candidateAccountId: string, campaignId: string, operations: PatchOperation[]) {
		return (
			await this.apiPatch<Campaign>(`/candidateaccounts/${candidateAccountId}/campaigns/${campaignId}`, operations)
		).Result
	}

	async removeCampaign(candidateAccountId: string, campaignId: string) {
		return (await this.apiDelete<Campaign>(`/candidateaccounts/${candidateAccountId}/campaigns/${campaignId}`)).Result
	}

	async updateSurvey(surveyId: string, operations: PatchOperation[]) {
		return (await this.apiPatch<Survey>(`/surveys/${surveyId}`, operations)).Result
	}

	async getSurveyById(surveyId: string) {
		return (await this.apiGet<Survey>(`/surveys/${surveyId}`)).Result
	}
}

export default new CampaignsApi()
