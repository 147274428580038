import { ActionContext, CommitOptions, DispatchOptions, Store as VuexStore } from 'vuex'

import type { RootState } from '@/store/index'
import { Upload } from 'tus-js-client'

export interface TusFileUploadingState {
	uploadInstance: null | Upload
	uploadProgressValue: number
	uploadedFileUrl: string
	uploadedFileInstance: File | Blob | null
	isUploading: boolean
}

export enum TusFileUploadingActionTypes {
	INIT_UPLOAD_INSTANCE = 'INIT_UPLOAD_INSTANCE',
	UPLOAD_IMAGE = 'UPLOAD_IMAGE',
	UPLOAD_CSV = 'UPLOAD_CSV',
	RESET_UPLOADED_CSV = 'RESET_UPLOADED_CSV',
	RESET_UPLOAD = 'RESET_UPLOAD'
}

export enum TusFileUploadingMutationTypes {
	SET_UPLOAD_INSTANCE = 'SET_UPLOAD_INSTANCE',
	SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS',
	SET_UPLOADED_FILE_URL = 'SET_UPLOADED_FILE_URL',
	SET_UPLOADING_STATUS = 'SET_UPLOADING_STATUS'
}

export type Mutations<S = TusFileUploadingState> = {
	[TusFileUploadingMutationTypes.SET_UPLOAD_INSTANCE](moduleState: S, instance: any): void
	[TusFileUploadingMutationTypes.SET_UPLOAD_PROGRESS](moduleState: S, progressValue: number): void
	[TusFileUploadingMutationTypes.SET_UPLOADED_FILE_URL](moduleState: S, url: string): void
	[TusFileUploadingMutationTypes.SET_UPLOADING_STATUS](moduleState: S, status: boolean): void
}

export type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<TusFileUploadingState, RootState>, 'commit'>

export interface Actions {
	[TusFileUploadingActionTypes.INIT_UPLOAD_INSTANCE](
		{ commit }: AugmentedActionContext,
		{ file, endpoint, onSuccess }: { file: File; endpoint: string; onSuccess?: () => void }
	): void
	[TusFileUploadingActionTypes.UPLOAD_IMAGE]({ dispatch }: AugmentedActionContext, image: File): void
	[TusFileUploadingActionTypes.UPLOAD_CSV](
		{ dispatch }: AugmentedActionContext,
		{ file, candidateAccountId }: { file: File; candidateAccountId: string }
	): void

	[TusFileUploadingActionTypes.RESET_UPLOADED_CSV]({ dispatch }: AugmentedActionContext, file: File): void

	[TusFileUploadingActionTypes.RESET_UPLOAD]({ commit, state }: AugmentedActionContext): void
}

export enum TusFileUploadingGettersType {
	GET_UPLOAD_INSTANCE = 'GET_UPLOAD_INSTANCE'
}

export type Getters<S = TusFileUploadingState> = {
	[TusFileUploadingGettersType.GET_UPLOAD_INSTANCE](moduleState: S): any
}

export type CandidateAccountStore<S = TusFileUploadingState> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
		key: K,
		payload: P,
		options?: CommitOptions
	): ReturnType<Mutations[K]>
} & {
	dispatch<K extends keyof Actions>(
		key: K,
		payload: Parameters<Actions[K]>[1],
		options?: DispatchOptions
	): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}
