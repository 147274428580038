import { ContactsQuery } from '@/api/contact-lists/models/ContactsQuery'
import { Voter, VoterId } from '@/api/contact-lists/models/Voter'
import { VoterDatabase } from '@/api/contact-lists/models/VoterDatabase'
import ItemsResult from '@/api/models/ItemsResult'
import ApiService from '@/services/api-service'

class VoterDatabasesApi extends ApiService {
	async getVoterDatabases() {
		return (await this.apiGet<{ Items: VoterDatabase[] }>('/voterdatabases')).Result?.Items || []
	}

	async searchInVoterDatabase(databaseId: string, payload: ContactsQuery) {
		return (
			await this.apiGet<ItemsResult<Voter>>(`/voterdatabases/${databaseId}/voters`, {
				params: payload
			})
		).Result
	}

	async searchInVoterDatabaseAndReturnAllResults(databaseId: string, payload: ContactsQuery) {
		return (
			await this.apiGet<VoterId[]>(`/voterdatabases/${databaseId}/voters/all`, {
				params: payload
			})
		).Result
	}
}
export default new VoterDatabasesApi()
