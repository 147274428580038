import { ActionTree, MutationTree, GetterTree, Module } from 'vuex'

import { CandidateAccountsApi, CandidateAccountUsersApi } from '@/api/candidate-account'
import logger from '@/services/log-service'
import type { RootState } from '@/store/index'
import { AuthGettersType } from '@/store/modules/auth/types'
import { showErrorMessage } from '@/utils/toast-message'

import { PublicCandidateAccountApp } from '@/api/candidate-account/models/PublicCandidateAccountApp'

import PatchOperation from '@/api/models/PatchOperation'
import { EditableCandidateAccountEvent } from '@/api/candidate-account/models/CandidateAccountEvent'
import {
	CandidateAccountState,
	Actions,
	CandidateAccountActionTypes,
	CandidateAccountMutationTypes,
	CandidateAccountGettersType,
	Mutations,
	Getters
} from './types'

export const candidateAccountState: CandidateAccountState = {
	candidateAccounts: [],
	fetchedCandidateAccount: null,
	selectedCandidateAccount: null
}

const actions: ActionTree<CandidateAccountState, RootState> & Actions = {
	async [CandidateAccountActionTypes.GET_ALL_CANDIDATE_ACCOUNTS]({ commit }, userEmail): Promise<boolean> {
		try {
			const accounts = await CandidateAccountsApi.getUserCandidateAccounts(userEmail.toLowerCase())

			commit(CandidateAccountMutationTypes.SET_CANDIDATE_ACCOUNTS, accounts)

			return true
		} catch (error) {
			logger.error('Something went wrong while fetching user candidate accounts', error)

			showErrorMessage(error)

			return false
		}
	},

	async [CandidateAccountActionTypes.GET_PLATFORM_APPS](
		_,
		{ candidateAccountId }
	): Promise<PublicCandidateAccountApp[]> {
		try {
			return await CandidateAccountsApi.getPlatformApps(candidateAccountId)
		} catch (error) {
			logger.error('Something went wrong while fetching platform apps', error)

			showErrorMessage(error)

			return []
		}
	},

	async [CandidateAccountActionTypes.CREATE_PLATFORM_APP](
		_,
		{ candidateAccountId, appName }
	): Promise<PublicCandidateAccountApp | null> {
		try {
			return await CandidateAccountsApi.createPlatformApp(candidateAccountId, appName)
		} catch (error) {
			logger.error('Something went wrong while fetching platform apps', error)

			showErrorMessage(error)

			return null
		}
	},

	async [CandidateAccountActionTypes.CREATE_CANDIDATE_ACCOUNT]({ commit, rootGetters }, candidateAccount) {
		try {
			const newAccount = await CandidateAccountsApi.createUserCandidateAccount(
				rootGetters[AuthGettersType.GET_CURRENT_USER_ID],
				candidateAccount
			)

			commit(CandidateAccountMutationTypes.ADD_NEW_CANDIDATE_ACCOUNT, newAccount)

			commit(CandidateAccountMutationTypes.SET_SELECTED_CANDIDATE_ACCOUNT, newAccount)

			return newAccount
		} catch (error) {
			logger.error('Something went wrong while creating a new candidate', error)

			showErrorMessage(error)

			return null
		}
	},

	[CandidateAccountActionTypes.SELECT_CANDIDATE_ACCOUNT]({ commit }, candidateAccount) {
		commit(CandidateAccountMutationTypes.SET_SELECTED_CANDIDATE_ACCOUNT, candidateAccount)
	},

	async [CandidateAccountActionTypes.ADD_CANDIDATE_ACCOUNT_STAFF](_, { candidateAccountId, candidateAccountStaff }) {
		try {
			if (candidateAccountId) {
				await CandidateAccountUsersApi.addStaffForCandidateAccount(candidateAccountId, candidateAccountStaff)
			}
		} catch (error) {
			logger.error('Something went wrong while adding a new staff member', error)

			showErrorMessage(error)
		}
	},

	async [CandidateAccountActionTypes.CLEAR_CANDIDATE_ACCOUNTS]({ commit }) {
		commit(CandidateAccountMutationTypes.SET_CANDIDATE_ACCOUNTS, [])
		commit(CandidateAccountMutationTypes.SET_SELECTED_CANDIDATE_ACCOUNT, null)
	},

	async [CandidateAccountActionTypes.FETCH_CANDIDATE_ACCOUNT]({ state, commit }, candidateAccountId) {
		try {
			const candidateAccount = await CandidateAccountsApi.getCandidateAccount(
				state.selectedCandidateAccount!.CreatedBy,
				candidateAccountId
			)

			commit(CandidateAccountMutationTypes.SET_FETCHED_CANDIDATE_ACCOUNT, candidateAccount)

			return candidateAccount
		} catch (error) {
			logger.error('Something went wrong while getting candidate account information', error)

			showErrorMessage(error)

			return null
		}
	},

	async [CandidateAccountActionTypes.UPDATE_CANDIDATE_ACCOUNT]({ state, commit }, { patchOperations, accountId }) {
		try {
			const updatedAccount = await CandidateAccountsApi.updateUserCandidateAccount(
				state.selectedCandidateAccount!.CreatedBy,
				accountId,
				patchOperations
			)

			commit(CandidateAccountMutationTypes.SET_FETCHED_CANDIDATE_ACCOUNT, updatedAccount)
		} catch (error) {
			logger.error('Something went wrong while updating candidate account tiles', error)

			showErrorMessage(error)
		}
	},

	async [CandidateAccountActionTypes.SEARCH_USERS](_, { accountId, phoneNumber }) {
		try {
			return await CandidateAccountUsersApi.searchUsers(accountId, phoneNumber)
		} catch (error: any) {
			logger.error('Something went wrong while fetching candidate accounts available activists', error)

			showErrorMessage(error)

			return []
		}
	},

	async [CandidateAccountActionTypes.SEARCH_USERS_V2](_, { accountId, query }) {
		try {
			return await CandidateAccountUsersApi.searchUsersV2(accountId, query)
		} catch (error: any) {
			logger.error('Something went wrong while fetching users', error)

			showErrorMessage(error)

			return { Items: [], Cursors: { After: '', Before: '' }, TotalCount: 0 }
		}
	},

	async [CandidateAccountActionTypes.UPDATE_USER](_, { accountId, userId, operations }) {
		try {
			return await CandidateAccountUsersApi.updateUser(accountId, userId, operations)
		} catch (error: any) {
			logger.error('Something went wrong while fetching users', error)

			showErrorMessage(error)

			return null
		}
	},

	async [CandidateAccountActionTypes.GET_EVENTS](_, { candidateAccountId }) {
		try {
			return await CandidateAccountsApi.getAllEvents(candidateAccountId)
		} catch (error) {
			logger.error('Something went wrong while fetching available events', error)

			showErrorMessage(error)

			return []
		}
	},

	async [CandidateAccountActionTypes.ADD_EVENT](_, { candidateAccountId, payload }) {
		try {
			return await CandidateAccountsApi.addEvent(candidateAccountId, payload)
		} catch (error) {
			logger.error('Something went wrong while adding new event', error)

			showErrorMessage(error)

			return null
		}
	},

	async [CandidateAccountActionTypes.REMOVE_EVENT](_, { candidateAccountId, eventId }) {
		try {
			await CandidateAccountsApi.removeEvent(candidateAccountId, eventId)

			return true
		} catch (error) {
			logger.error('Something went wrong while adding new event', error)

			showErrorMessage(error)

			return false
		}
	},

	async [CandidateAccountActionTypes.UPDATE_EVENT](_, { candidateAccountId, eventId, payload }) {
		try {
			const operations: PatchOperation[] = []

			Object.keys(payload).forEach((key) => {
				operations.push({
					path: `/${key}`,
					value: payload[key as keyof EditableCandidateAccountEvent],
					op: 'replace'
				})
			})

			await CandidateAccountsApi.updateEvent(candidateAccountId, eventId, operations)

			return true
		} catch (error) {
			logger.error('Something went wrong while updating event', error)

			showErrorMessage(error)

			return Promise.resolve(false)
		}
	}
}

const mutations: MutationTree<CandidateAccountState> & Mutations = {
	[CandidateAccountMutationTypes.SET_CANDIDATE_ACCOUNTS](moduleState, candidateAccounts) {
		moduleState.candidateAccounts = candidateAccounts
	},

	[CandidateAccountMutationTypes.SET_FETCHED_CANDIDATE_ACCOUNT](moduleState, account) {
		moduleState.fetchedCandidateAccount = account
	},

	[CandidateAccountMutationTypes.SET_SELECTED_CANDIDATE_ACCOUNT](moduleState, candidateAccount) {
		moduleState.selectedCandidateAccount = candidateAccount
	},

	[CandidateAccountMutationTypes.ADD_NEW_CANDIDATE_ACCOUNT](moduleState, newCandidateAccount) {
		moduleState.candidateAccounts = [...moduleState.candidateAccounts, newCandidateAccount]
	}
}

export const getters: GetterTree<CandidateAccountState, RootState> & Getters = {
	[CandidateAccountGettersType.GET_SELECTED_CANDIDATE_ACCOUNT](moduleState) {
		return moduleState.selectedCandidateAccount
	},

	[CandidateAccountGettersType.GET_CANDIDATE_ACCOUNT_BY_ID]: (moduleState) => (candidateAccountId) => {
		return moduleState.candidateAccounts.find((item) => item.Id === candidateAccountId)
	}
}

export const store: Module<CandidateAccountState, RootState> = {
	state: candidateAccountState,
	getters,
	mutations,
	actions
}
