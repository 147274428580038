import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

import { AuthState } from './modules/auth/types'
import { CandidateAccountState } from './modules/candidate-account/types'
import { ContactListsState } from './modules/contact-lists/types'
import { TusFileUploadingState } from './modules/tus-file-uploading/types'
import { CampaignsState } from './modules/campaigns/types'
import { StatisticsState } from './modules/statistics/types'
import { store as auth } from './modules/auth'
import { store as candidateAccount } from './modules/candidate-account'
import { store as contactLists } from './modules/contact-lists'
import { store as tusFileUploading } from './modules/tus-file-uploading'
import { store as campaigns } from './modules/campaigns'
import { store as statistics } from './modules/statistics'

const ls = new SecureLS({ isCompression: false })

export type RootState = {
	auth: AuthState
	candidateAccount: CandidateAccountState
	contactLists: ContactListsState
	tusFileUploading: TusFileUploadingState
	campaigns: CampaignsState
	statistics: StatisticsState
}

// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore({
	modules: {
		auth,
		candidateAccount,
		contactLists,
		tusFileUploading,
		campaigns,
		statistics
	},
	plugins: [
		createPersistedState({
			paths: ['auth.user', 'candidateAccount.selectedCandidateAccount', 'candidateAccount.candidateAccounts'],
			storage: {
				getItem: (storageKey) => ls.get(storageKey),
				setItem: (storageKey, value) => ls.set(storageKey, value),
				removeItem: (storageKey) => ls.remove(storageKey)
			}
		})
	]
})

export function useStore() {
	return baseUseStore(key)
}
