export enum UserRole {
	Admin = 'Admin',
	CampaignManager = 'Campaign Manager',
	// RegionalManager = 'RegionalManager',
	Staff = 'Staff',
	Activist = 'Activist',
	// Donor = 'Donor',
	DetailsPendingActivist = 'DetailsPendingActivist',
	PendingActivist = 'PendingActivist',
	Follower = 'Follower'
}

export const userRoleTitle = (role: UserRole): string => {
	switch (role) {
		case UserRole.CampaignManager:
			return 'Campaign Manager'
		// case UserRole.RegionalManager:
		// 	return 'Regional Manager'
		case UserRole.Activist:
			return 'Approved Activist'
		case UserRole.DetailsPendingActivist:
			return 'Activist (No Name Info)'
		case UserRole.PendingActivist:
			return 'Pending Activist'
		default:
			return UserRole[role]
	}
}

export interface UserPlatformRole {
	Id: string
	UserId: string
	RoleName: UserRole
	ResourceUri: string
}

export type UserRolesResponse = UserPlatformRole[]
